import React from 'react';

import { Chat, ChatPlugins } from '@common/react/components/Chat/Chat';

import { useChatSettingsProviderContext } from '@common/react/components/Chat/ChatSettingsProvider';
import TypingComponent from '@common/react/components/Chat/TypingPlugin/TypingComponent';

import '@common/react/scss/components/typingComponent.scss';

interface Props {
	chat: Chat;
	render: (onKeyDown) => React.ReactNode;
	prefix?: string;
}

const TypingWrapper: React.FC<Props> = ({ chat, prefix = '', render }) => {
	const lastTyping = React.useRef<number | undefined>();
	const context = useChatSettingsProviderContext();

	if (!context?.state) throw 'need ChatSettingsContext';

	const { state: { requests, plugins, request } } = context;
	const delay = plugins[ChatPlugins.Typing]?.options?.delay ?? 5000;

	const node = React.useCallback((contacts) => {
		const res = contacts.map((contact) => `${contact.firstName} ${contact.lastName}`).join(', ');

		return <div className="tag-chat__typings-container">
			{!!contacts.length && <div className="tag-chat__typing">
				{`${res} ${contacts.length > 1 ? ' are typing' : ' is typing'}`}
			</div>}
		</div>;
	}, []);

	const keyDownHandler = React.useCallback((e: React.KeyboardEvent<HTMLTextAreaElement>) => {
		const k = e.which;

		if (k >= 48 && k <= 90 && chat) {
			const typingDate = Date.now();
			if ((!lastTyping.current || (typingDate - lastTyping.current > delay))) {
				lastTyping.current = typingDate;

				request(requests.typing, { chatId: chat.id })
					.catch(console.log);
			}
		}
	}, []);

	return <>
		<TypingComponent chat={chat} render={node} />
		{render(keyDownHandler)}
	</>;
};

export default TypingWrapper;
