import * as React from 'react';

import Tag from 'antd/lib/tag';

import LinkWithPrevLocation from '@common/react/components/UI/LinkWithPrevLocation/LinkWithPrevLocation';

import zocDocLogo from '@images/zocDoc.png';
import nopaliLogo from '@images/nopaliLogo.jpg';
import chronoLogo from '@images/drchrono.jpg';
import podiumLogo from '@images/podium.png';
import inquiryLogo from '@images/inquiry.png';
import tunaLogo from '@images/tuna.png';
import drdxLogo from '@images/drdx.png';

export const logoDictionary: object = {
	zocDoc: zocDocLogo,
	nopali: nopaliLogo,
	doctorChrono: chronoLogo,
	chrono: chronoLogo,
	locationChrono: chronoLogo,
	podium: podiumLogo,
	inquiry: inquiryLogo,
	portal: tunaLogo,
	drdx: drdxLogo,
};

interface ComponentProps {
	item: any;
	style?: React.CSSProperties;
	className?: string | null;
	withLabel?: boolean;
	withTag?: boolean;
	tagClassName?: string;
	withLink?: boolean;
	hideItems?: Array<string>;
}

const tagColor = {
	zocDoc: '',
	nopali: 'gold',
	doctorChrono: '',
	chrono: 'cyan',
	locationChrono: '',
	podium: '',
	inquiry: 'blue',
	drdx: '',
};

const linkPath = {
	zocDoc: '',
	nopali: '',
	doctorChrono: '',
	chrono: '',
	locationChrono: '',
	podium: '',
	inquiry: '/inquiry-editor/',
	drdx: '',
};

export const Logo: React.FC<ComponentProps> = ({
	style,
	item,
	className = '',
	withLabel = false,
	withTag = false,
	tagClassName,
	withLink,
	hideItems,
}) => {
	return (
		<>
			{item && Object.keys(logoDictionary).map((key) => {
				if (hideItems && hideItems.includes(key)) {
					return null;
				}

				const value = typeof item[`${key}Id`] !== 'undefined' && item[`${key}Id`] !== null
					? item[`${key}Id`] : item[`${key}Name`] || item[`${key}Location`];

				return typeof value !== 'undefined' && value !== '' && value !== null && (
					<span key={key}>
						{withTag && !!tagColor[key]
							? (
								<div className={tagClassName}>
									{withLink && !!linkPath[key]
										? (
											<LinkWithPrevLocation to={`${linkPath[key]}${value}`}>
												<Tag color={tagColor[key]}>
													<img
														style={{ ...style, margin: '0 0 2px' }}
														className={className === null ? '' : `logo ${className}`}
														src={logoDictionary[key]}
														title={key === 'portal' ? 'NorthernTuna Patient Portal' : `${key} ${value}`}
														alt={key}
													/>
													{withLabel && <span className="tag__label">{value}</span>}
												</Tag>
											</LinkWithPrevLocation>
										)
										: (
											<Tag color={tagColor[key]}>
												<img
													style={{ ...style, margin: '0 0 2px' }}
													className={className === null ? '' : `logo ${className}`}
													src={logoDictionary[key]}
													title={`${key === 'portal' ? 'NorthernTuna Patient Portal, id:' : key} ${value}`}
													alt={key}
												/>
												{withLabel && <span className="tag__label">{value}</span>}
											</Tag>
										)}
								</div>
							) : (
								<>
									{withLink && !!linkPath[key] ? (
										<LinkWithPrevLocation to={`${linkPath[key]}${value}`}>
											<img
												style={style}
												className={className === null ? '' : `logo ${className}`}
												src={logoDictionary[key]}
												title={key === 'portal' ? 'NorthernTuna Patient Portal' : `${key !== 'drdx' ? key : ''} ${value}`}
												alt={key}
											/>
										</LinkWithPrevLocation>
									) : (
										<img
											style={style}
											className={className === null ? '' : `logo ${className}`}
											src={logoDictionary[key]}
											title={key === 'portal' ? 'NorthernTuna Patient Portal' : `${key !== 'drdx' ? key : ''} ${value}`}
											alt={key}
										/>
									)}
									{withLabel && (
										<>
											{value}
											<br />
										</>
									)}
								</>
							)}
					</span>
				);
			})}
		</>
	);
};

export default Logo;
