import React from 'react';

import Dropdown from 'antd/lib/dropdown';
import Message from 'antd/lib/message';

import Button from '@common/react/components/Forms/Button';
import {
	useChatSettingsProviderContext,
} from '@common/react/components/Chat/ChatSettingsProvider';
import { ChatMessageType, ChatStickerCollection } from '@common/react/components/Chat/Chat';
import '@common/react/scss/components/chatStickerComponent.scss';

interface StickerComponentProps {
	chatId: number;
	skeleton?: string;
	collections: Array<ChatStickerCollection>;
	getPopupContainer?: (node) => HTMLElement;
}

interface StickerComponentMenuProps {
	collections: Array<ChatStickerCollection>;
	sendSticker: (path, fullPath?: string, description?: string) => void;
	skeleton?: string;
}

interface StickerComponentItemProps {
	src: string;
	skeleton?: string;
	description?: string;
	className?: string;
	onClick?: () => void;
}

const StickerComponentItem: React.FC<StickerComponentItemProps> = (props) => {
	const {
		src, skeleton, description, className = 'sticker-component_item', onClick,
	} = props;
	const [error, setError] = React.useState(false);
	const handleClick = () => {
		if (!error && src) {
			onClick && onClick();
		}
	};

	React.useEffect(() => {
		if (error) setError(false);
	}, [src]);

	return <div className={className} title={description} onClick={handleClick}>
		<img
			src={error ? skeleton : src}
			alt={description}
			onError={() => setError(true)}
		/>
	</div>;
};

const StickerComponentMenu: React.FC<StickerComponentMenuProps> = ({ collections, skeleton, sendSticker }) => {
	const [collection, setCollection] = React.useState<number>(0);

	return <div className="sticker-component-menu">
		<div className="selected-collection sticker-component-menu__selected-collection">
			{
				collections[collection]
					? collections[collection].items.length
						? <div className="selected-collection__list">
							{collections[collection].items.map((item) =>
								<div className="selected-collection__list_item-wrapper" key={item.image}>
									<StickerComponentItem
										onClick={() => sendSticker(item.image, item.fullImage, item.description)}
										src={item.image}
										description={item.description}
										skeleton={skeleton}
									/>
								</div>)}
						</div>
						: <h3>The selected group of stickers is empty</h3>
					: <h3>No sticker group selected</h3>
			}
		</div>
		<div
			className="sticker-component-menu__collections"
			onClick={(e) => {
				e.stopPropagation();
				e.preventDefault();
			}}
		>
			{collections.map((item, index) => <StickerComponentItem
				key={item.image}
				src={item.image}
				description={item.description}
				skeleton={skeleton}
				className={`sticker-component-menu__collection sticker-component-menu__collection${collection === index ? '__selected' : ''}`}
				onClick={() => setCollection(index)}
			/>)}
		</div>
	</div>;
};

const StickerComponent: React.FC<StickerComponentProps> = (props) => {
	const context = useChatSettingsProviderContext();

	if (!context?.state) throw 'need ChatSettingsContext';

	const { state: { requests, request } } = context;
	const [open, setOpen] = React.useState(false);
	const [loading, setLoading] = React.useState(false);

	const sendSticker = (path, fullPath, description) => {
		const item = {
			id: -1,
			text: `[${path}][${fullPath || ''}][${description || ''}]`,
			chatId: props.chatId,
			bytes: [],
			files: [],
			attachments: [],
			chatMessageType: ChatMessageType.Sticker,
		};
		setLoading(true);
		setOpen(false);

		request(requests.chatMessage, item)
			.catch(Message.error)
			.finally(() => setLoading(false));
	};

	return <Dropdown
		trigger={['click']}
		placement="topLeft"
		open={open}
		onOpenChange={setOpen}
		getPopupContainer={props.getPopupContainer}
		menu={{
			items: [{
				key: 1,
				label: <>
					<StickerComponentMenu
						sendSticker={sendSticker}
						skeleton={props?.skeleton}
						collections={props?.collections || []}
					/>
				</>,
			}],
		}}
	>
		<Button
			type="button"
			title="Stickers"
			className="btn btn-sm btn-default chat-message-item"
			isLoading={loading}
			disabled={loading}
		>
			<i className="fa fa-tags" />
		</Button>
	</Dropdown>;
};

export default StickerComponent;
