import * as React from 'react';
import { shallowEqual, useSelector } from 'react-redux';

import LinkWithPrevLocation from '@common/react/components/UI/LinkWithPrevLocation/LinkWithPrevLocation';

import { ApplicationState } from '@app/store';

import '@app/scss/components/footer.scss';

const year = new Date().getFullYear();

const Footer: React.FC = () => {
	const hostOptions = useSelector((state: ApplicationState) => (state.hostOptions?.item), shallowEqual);

	return (
		<footer className="site-footer">
			<div className="container row-content">
				<div className="site-footer__copyright">
					<span className="site-footer__copyright__text">
						©
						{' '}
						{year}
						{' '}
						NorthernTuna, all rights reserved.
					</span>
					<span>
						<LinkWithPrevLocation className="ml10" to="/privacy-policy">Privacy Policy</LinkWithPrevLocation>
						&nbsp;
						<a className="ml10 mr10" href={hostOptions?.northernTunaPortalAddress} target="_blank" rel="noreferrer">Patients Portal</a>
					</span>
				</div>
				<div className="nt-logo">
					<img src="/amp-assets/images/logo-350x62.png" height={36} alt="Northern Tuna" />
				</div>
			</div>
		</footer>
	);
};

export default Footer;
