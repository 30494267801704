import React from 'react';
import { Redirect, RouteProps } from 'react-router-dom';
import { shallowEqual, useSelector } from 'react-redux';

import once from 'lodash/once';

import { BaseUser } from '@common/react/objects/BaseUser';
import { useApplicationContext } from '@common/react/components/Core/Application/Application';
import { BaseApplicationState } from '@common/react/store';

interface ComponentProps<TUser extends BaseUser> extends RouteProps {
	path: string;
	canAccess?: (user: TUser, route: { roles: Array<number> }) => boolean;
	redirectPath?: string;
	children?: any;
}

export interface SecureRoutesProviderContext {
	canAccess: (path: string) => boolean;
}

export const createSecureRoutesProviderContext = once(
	<TUser extends BaseUser = BaseUser>() => React.createContext({} as SecureRoutesProviderContext),
);

export const useSecureRoutesProviderContext: () => SecureRoutesProviderContext = () => React.useContext(createSecureRoutesProviderContext());

const canRoleAccess = (route, role) =>
	!route.roles || (route.roles.length > 0 && route.roles.includes(role));

export const BaseSecureRouteWrapper = <TUser extends BaseUser, >(props: ComponentProps<TUser>) => {
	const SecureRoutesProviderContext = createSecureRoutesProviderContext();
	const routesPropsPagesMap = useSelector((state: BaseApplicationState<TUser> & { routesPropsPagesMap }) =>
		state.routesPropsPagesMap?.item, shallowEqual);

	const baseCanAccess = (user, route): boolean => {
		return Boolean(!route || (user && (user.root
			|| canRoleAccess(route, user.role))));
	};

	const {
		path,
		children,
		redirectPath,
		canAccess: propsCanAccess = baseCanAccess,
	} = props;
	const { getUser } = useApplicationContext();
	const user = getUser<TUser>();

	const canAccess = (path: string): boolean => {
		const slashIndex = path.lastIndexOf('/');

		const mainPath = path.substring(1, slashIndex > 0 ? slashIndex : path.length);
		const route = routesPropsPagesMap?.[mainPath];

		return propsCanAccess(user, route);
	};

	if (!user && redirectPath) {
		return <Redirect to={redirectPath} />;
	}

	return (
		<SecureRoutesProviderContext.Provider value={{ canAccess }}>
			{canAccess(path) ? children : <Redirect to="/access-denied" />}
		</SecureRoutesProviderContext.Provider>
	);
};

export default BaseSecureRouteWrapper;
