import moment from 'moment';

import { BaseParams } from '@common/react/objects/BaseParams';
import { getSearchParamsFromUrl } from '@common/react/utils/FIltersParamsFromUrl/FiltersParamsFromUrl';

import { DashboardTimeInterval } from '@commonTuna/react/components/Utils';
import { getIntervalTimes } from '@commonTuna/react/components/UI/TimeIntervalRadio/TimeIntervalRadio';

import { OrderTargetType, OrderType, PaymentType } from '@app/objects/Order';
import { UserRole } from '@app/objects/User';
import { OrderStatusFilterEnum } from '@app/objects/Appointment';
import {
	appointmentAltFiltersMap,
} from '@app/components/Pages/Admin/Appointments/AppointmentsWithEditableTable/AppointmentsETFilters';
import { ActiveFilterEnum } from '@app/objects/Patient';
import { SystemTypes } from '@app/objects/Dashboard';

const [timeMin, timeMax] = getIntervalTimes(DashboardTimeInterval.Month, 0);

export enum TimePriority {
	Appointment = 0,
	Order = 1,
	Inquiry = 2,
	Batch = 3,
	CreatedPatient = 4,
	Quote = 5,
	Review = 6,
	BeforeAfter = 7,
	Analysis = 8,
	Claim = 9,
	Email = 10,
	Mixed = 11,
	CheckDate = 12,
	ProcessedTime = 13,
}

export const TimePriorityNames = {
	[TimePriority.Appointment]: 'Appointment',
	[TimePriority.Order]: 'Order',
	[TimePriority.Inquiry]: 'Inquiry',
	[TimePriority.Batch]: 'Batch',
	[TimePriority.CreatedPatient]: 'Patient Creation',
	[TimePriority.Quote]: 'Quote',
	[TimePriority.Review]: 'Review',
	[TimePriority.BeforeAfter]: 'Before-After',
	[TimePriority.Analysis]: 'Lab. Order',
	[TimePriority.Claim]: 'Claim',
	[TimePriority.Email]: 'Email',
	[TimePriority.Mixed]: 'Mixed',
	[TimePriority.CheckDate]: 'Check Date',
	[TimePriority.ProcessedTime]: 'Processed Time',
};

export const TimePriorityIcon = {
	[TimePriority.Appointment]: 'fa-calendar-check-o',
	[TimePriority.Order]: 'fa-clock-o',
	[TimePriority.Inquiry]: 'fa-question-circle-o',
	[TimePriority.Batch]: 'fa-university',
	[TimePriority.CreatedPatient]: 'fa-user',
	[TimePriority.Quote]: 'fa-credit-card-alt',
	[TimePriority.Review]: 'fa-pencil',
	[TimePriority.BeforeAfter]: 'fa-star',
	[TimePriority.Analysis]: 'fa-flask',
	[TimePriority.Claim]: 'fa-medkit',
	[TimePriority.Email]: 'fa-envelope',
	[TimePriority.Mixed]: 'fa-bars',
	[TimePriority.CheckDate]: 'fa-dollar',
	[TimePriority.ProcessedTime]: 'fa-rotate-right',
};

export enum TargetType {
	All,
	Targets,
	Payment
}

export interface TimeKey {
	year: number;
	part: number;
}

export const NullableAppointmentType = -2;
export const NullableLocation = -2;
export const NullableDisease = -2;
export const NullableProcedureGroup = -2;
export const NullableVital = -2;
export const NullableIcdCode = -2;

export const getDefaultPaymentTypes = () => [
	PaymentType.Cash,
	PaymentType.CreditCard,
	PaymentType.DebitCard,
	PaymentType.Check,
	PaymentType.ElectronicPayment,
];

export enum AppointmentReportFilters {
	/* --- Start Appointment Filters ---*/
	ReviewStatus,
	ClaimStatusIds,
	RoomIds,
	DiseaseId,
	PayerId,
	DoctorIds,
	AppointmentTypeIds,
	ProcedureIds,
	ProcedureGroupIds,
	UserIds,
	SpecialtyIds,
	ProfessionIds,
	InnerStatusIds,
	InnerLocationId,
	GlobalProcedureIds,
	SystemType,
	ObjectHistoryType,
	MainProcedures,
	Disabled,
	ByWorkingTimes,
	AppointmentRoles,
	HasProcedureGroup,
	HasAppointmentProcedure,
	isActive,
	CallOnly,
	HasCopyToId,
	Email,
	SMS,
	Push,
	Call,
	InventoryProcedureIds,
	InventoryProductIds,
	InventorySpecialsIds,
	Inventory,
	HasAppointmentInsurance,
	/* --- End Appointment Filters ---*/
	/* --- Start Patient Filters ---*/
	PatientId,
	Gender,
	IsPatientActive,
	HasInsurance,
	HasDrivingLicense,
	IsEmailValid,
	EthnicityId,
	ParentId,
	ChildId,
	PatientParentType,
	HasEmail,
	HasCellPhone,
	BalanceMinMax,
	AgeMinMax,
	AppointmentsMinMax,
	EmailsMinMax,
	DebtMinMax,
	DefaultDoctorId,
	DefaultLocationId,
	PatientFirstName,
	PatientLastName,
	PatientEmail,
	PatientPhone,
	OrdersMinMax,
	PhotosMinMax,
	FilesMinMax,
	Allergies,
	Vitals,
	PatientZip,
	/* --- End Patient Filters ---*/
	/* --- Start Order Filters ---*/
	OrderStatus,
	OrderTypes,
	PaymentTypes,
	ProductIds,
	SpecialsIds,
	OrderDiseaseId,
	OrderPayerId,
	OrderDoctorIds,
	OrderAppointmentTypeIds,
	OrderProcedureIds,
	OrderProcedureGroupIds,
	OrderUserIds,
	OrderSpecialtyIds,
	OrderProfessionIds,
	OrderGlobalProcedureIds,
	OrderDisabled,
	OrderPriorityFiltering,
	Partition,
	ReportSourceType,
	ReportSourceTypes,
	InsuranceCreditNormal,
	RoundToggleButton,
	ZeroColumnsButton,
	SPSDFilters,
	SubmittedOrderId,
	PriceMinMax,
	WithTips,
	TargetType,
	ProductUsable,
	OrderRoles,
	HasProductCategory,
	Category,
	OrderHasProcedureGroup,
	HasDoctorProcedure,
	GiftCard,
	ProductActive,
	RelatedProcedureIds,
	RelatedProductIds,
	RelatedSpecialsIds,
	OrderFromPortal,
	/* --- End Order Filters ---*/
	/* --- Start Inquiry Filters ---*/
	InnerInquiryLocationId,
	OuterInquiryLocationId,
	OuterInquiryStatusSelect,
	InnerInquiryStatusSelect,
	InquiryDoctorId,
	InquiryMainPageId,
	InquiryPageId,
	InquiryDate,
	InquiryDevices,
	InquiryPath,
	InquiryHasAppointment,
	InquirySystem,
	/* --- End Inquiry Filters ---*/
	/* --- Start Outer Filters ---*/
	TimeIntervalRadio,
	OuterLocationId,
	OuterStatusIds,
	Text,
	OuterFilters,
	ReceivedValue,
	/* --- End Outer Filters ---*/
	/* --- Start Template Filters ---*/
	TemplateFilter,
	/* --- End Template Filters ---*/
}

export const TimeIntervals = [
	DashboardTimeInterval.Day,
	DashboardTimeInterval.Week,
	DashboardTimeInterval.Month,
	DashboardTimeInterval.Quarter,
	DashboardTimeInterval.Year,
];

export enum ReportSourceType
{
	Inquiries = 0,
	Appointment = 1,
	Time = 2,
	Patient = 3,
	Orders = 4,
	Payments = 5,
	Revenue = 6,
	RevenuePerHour = 7,
	RevenuePerProvider = 8,
	RevenuePerAppointment = 9,
	RevenuePerPatient = 10,
	Profit = 11,
	ProfitPerHour = 12,
	ProfitPerProvider = 13,
	ProfitPerAppointment = 14,
	ProfitPerPatient = 15,
	Cost = 16,
	Commission = 17
}

export const ReportSourceTypeExclude = [
	ReportSourceType.Appointment,
	ReportSourceType.Payments,
	ReportSourceType.Patient,
	ReportSourceType.Orders,
	ReportSourceType.Inquiries,
	ReportSourceType.RevenuePerAppointment,
	ReportSourceType.RevenuePerPatient,
	ReportSourceType.ProfitPerAppointment,
	ReportSourceType.ProfitPerPatient,
	ReportSourceType.Cost,
	ReportSourceType.Time,
];

export const ReportSourceTypeNames = {
	[ReportSourceType.Appointment]: 'Appointments',
	[ReportSourceType.Patient]: 'Patients',
	[ReportSourceType.Orders]: 'Orders',
	[ReportSourceType.Inquiries]: 'Inquiries',
	[ReportSourceType.Payments]: 'Payments',
	[ReportSourceType.Revenue]: 'Revenue',
	[ReportSourceType.RevenuePerHour]: 'Revenue Per Hour',
	[ReportSourceType.RevenuePerProvider]: 'Revenue Per Provider',
	[ReportSourceType.RevenuePerAppointment]: 'Revenue Per Appointment',
	[ReportSourceType.RevenuePerPatient]: 'Revenue Per Patient',
	[ReportSourceType.Profit]: 'Profit',
	[ReportSourceType.ProfitPerHour]: 'Profit Per Hour',
	[ReportSourceType.ProfitPerProvider]: 'Profit Per Provider',
	[ReportSourceType.ProfitPerAppointment]: 'Profit Per Appointment',
	[ReportSourceType.ProfitPerPatient]: 'Profit Per Patient',
	[ReportSourceType.Cost]: 'Cost',
	[ReportSourceType.Commission]: 'Commission',
	[ReportSourceType.Time]: 'Total Duration',
};

export enum ReportServiceType {
	Procedure = 0,
	Product = 1,
	Specials = 2,
	Group = 3,
	ProductCategories = 4
}

export const ReportServiceTypeNames = {
	[ReportServiceType.Procedure]: 'Service',
	[ReportServiceType.Product]: 'Product',
	[ReportServiceType.Specials]: 'Specials',
	[ReportServiceType.Group]: 'Group',
	[ReportServiceType.ProductCategories]: 'Product Categories',
};

export const disabledFilterGroup = [
	{ caption: 'Inactive', value: true },
	{ caption: 'Active', value: false },
	{ caption: 'All', value: undefined },
];

export const templateSignedFilterGroup = [
	{ caption: 'Signed', value: true },
	{ caption: 'Not Signed', value: false },
	{ caption: 'All', value: undefined },
];

export const objectByWorkingTimes = [
	{ caption: 'Filter by working doctors', value: true },
	{ caption: 'All', value: false },
];

export const hasAppointmentGroup = [
	{ caption: 'With Appointment', value: true },
	{ caption: 'Without Appointment', value: false },
	{ caption: 'All', value: null },
];

export interface YearInterval {
	partition: DashboardTimeInterval;
	part: number | null;
}

export const YearIntervalInitial: YearInterval = {
	partition: DashboardTimeInterval.Month,
	part: null,
};

export const ReportOrderType = {
	[OrderType.Buy]: 'Order',
	[OrderType.Quote]: 'Quote',
	[OrderType.Refund]: 'Refund',
};

export const getDefaultOrderFilter = () => {
	return {
		orderStatus: OrderStatusFilterEnum.Complete,
		orderTypes: [OrderType.Buy],
		orderTargetTypes: [
			OrderTargetType.Service,
			OrderTargetType.Product,
			OrderTargetType.Special,
			OrderTargetType.Deposit,
			OrderTargetType.Tip,
		],
	};
};

export enum ReceivedValue {
	PaymentsBased = 0,
	Accrual = 1,
	CashBased = 2
}

export interface SearchParams {
	text?: string | null;
	timeMin: number | null;
	timeMax: number | null;
	doctorId?: number | null;
	doctorIds?: Array<number> | null;
	procedureId?: number | null;
	procedureIds?: Array<number> | null;
	productId?: number | null;
	productIds?: Array<number> | null;
	procedureGroupIds?: Array<number> | null;
	professionId?: number | undefined;
	professionIds?: Array<number> | null;
	locationId?: number | undefined;
	count?: number;
	pageSize?: number;
	withInsurance?: boolean;
	withCredit?: boolean;
	withNormal?: boolean;
	partition: number;
	part?: number | null;
	patientId?: number | undefined;
	statusIds?: Array<number> | undefined;
	reportSourceType: ReportSourceType;
	reportSourceTypes: Array<ReportSourceType>;
	systemType: SystemTypes;
	orderStatus: OrderStatusFilterEnum;
	hasRefund?: boolean | null;
	disabled?: boolean | null;
	banned?: boolean | null;
	roles?: Array<UserRole> | [];
	reportServiceType: ReportServiceType;
	orderTimePriority: boolean;
	timePriority: TimePriority;
	userIds?: Array<number> | undefined;
	fromHistory?: boolean;
	paymentTypes?: Array<PaymentType>;
	orderTargetTypes?: Array<OrderTargetType>;
	patientText?: string;
	orderPriorityFiltering: boolean;
	withQuotas: false;
	isPatientActive: ActiveFilterEnum;
	targetType: TargetType;
	systemTypeColumns: Array<SystemTypes>;
	byWorkingTimes: boolean;
	receivedValue: ReceivedValue;
	withTimePeriod: boolean;
	page: number;
}

export const InitialFilters: SearchParams = {
	timeMax,
	timeMin,
	withInsurance: true,
	withCredit: true,
	withNormal: true,
	partition: DashboardTimeInterval.Week,
	part: null,
	reportSourceType: ReportSourceType.Appointment,
	reportSourceTypes: [],
	systemType: SystemTypes.All,
	disabled: false,
	roles: [],
	reportServiceType: ReportServiceType.Procedure,
	orderTimePriority: true,
	timePriority: TimePriority.Appointment,
	fromHistory: false,
	patientText: '',
	orderPriorityFiltering: false,
	withQuotas: false,
	isPatientActive: ActiveFilterEnum.Active,
	targetType: TargetType.All,
	systemTypeColumns: [],
	byWorkingTimes: false,
	receivedValue: ReceivedValue.CashBased,
	withTimePeriod: true,
	page: 1,
	...getDefaultOrderFilter(),
};

export const resolveInterval = (params, currentFilters) => {
	if (currentFilters?.timeMax && currentFilters.timeMax < params.end) {
		params.end = currentFilters.timeMax;
		params.isPartial = true;
	}
	if (currentFilters?.timeMin && currentFilters.timeMin > params.start) {
		params.start = +currentFilters.timeMin;
		params.isPartial = true;
	}
	return params;
};

export interface TimeData {
	start: number;
	end: number;
	isPartial: boolean;
	caption: string;
}

export const dollarIcon = (sourceType: ReportSourceType): any =>
	(sourceType === ReportSourceType.Payments || !ReportSourceTypeNotDollar.some((v) => v === sourceType)
		? '$'
		: null);

export const getTimeInterval: (a: any, b: {partition, timeMin, timeMax}, offset?: string) => any = (record, currentFilters, offset) => {
	return resolveInterval(getTimeData(record.timeKey, currentFilters, undefined, offset), currentFilters);
};

// week starts from Sunday, first week of year contains January 4
export const getStartOfWeek = (utcMoment: () => moment.Moment, year: number, weekNumber: number) => {
	const firstDayOfYear = utcMoment().year(+year).startOf('year').add(3, 'days');
	const startOfFirstWeek = firstDayOfYear.weekday(0);
	return startOfFirstWeek.add((weekNumber - 1) * 7, 'days');
};

export const getTimeData = (record: TimeKey, currentFilters, withoutYear?: boolean, offset?: string, isLeapYear?: boolean): TimeData => {
	let start: number = 0;
	let end: number = 0;
	let caption: string = '';
	let isPartial;
	const utcMoment = () => (offset ? moment().utcOffset(offset) : moment().utc());
	switch (currentFilters.partition) {
		case DashboardTimeInterval.Year:
			start = utcMoment().year(+record.year).startOf('year').valueOf();
			end = utcMoment().year(+record.year).endOf('year').valueOf();
			caption = utcMoment().year(+record.year).format('YYYY');
			break;
		case DashboardTimeInterval.Quarter:
			start = utcMoment().year(+record.year).quarter(+record.part).startOf('quarter')
				.valueOf();
			end = utcMoment().year(+record.year).quarter(+record.part).endOf('quarter')
				.valueOf();
			caption = `${!withoutYear ? `${utcMoment().year(+record.year).format('YYYY')} ` : ''}Quarter #${+record.part}`;
			break;
		case DashboardTimeInterval.Month:
			start = utcMoment().year(+record.year).month(+record.part - 1).startOf('month')
				.valueOf();
			end = utcMoment().year(+record.year).month(+record.part - 1).endOf('month')
				.valueOf();
			caption = `${!withoutYear
				? `${utcMoment().year(+record.year).format('YYYY')} `
				: ''}${utcMoment().month(+record.part - 1).format('MMMM')}`;
			break;
		case DashboardTimeInterval.Week:
			const startWeek = getStartOfWeek(utcMoment, +record.year, +record.part);
			const endWeek = startWeek.clone().add(7, 'days');
			/* const startWeek = utcMoment().year(+record.year).startOf('week').week(+record.part);
			const endWeek = utcMoment().year(+record.year).endOf('week').week(+record.part); */
			/* if (utcMoment().startOf('week').week(+record.part).year() < utcMoment().endOf('week').week(+record.part).year()) {
				startWeek = utcMoment().year(+record.year).startOf('week').week(+record.part);
			} */
			({ start, end, isPartial } = resolveInterval({ start: startWeek.valueOf(), end: endWeek.valueOf() }, currentFilters));
			const sWeek = moment(start).utc(); const
				eWeek = moment(end).utc();
			caption = `${!withoutYear
				? `${utcMoment().year(+record.year).format('YYYY')} `
				: ''}Week #${+record.part}: ${sWeek.format('MMMM DD')} - ${eWeek.format('MMMM DD')}`;
			break;
		case DashboardTimeInterval.Day:
			start = utcMoment().year(+record.year).dayOfYear(+record.part).startOf('day')
				.valueOf();
			end = utcMoment().year(+record.year).dayOfYear(+record.part).endOf('day')
				.valueOf();
			caption = `${!withoutYear
				? `${utcMoment().year(+record.year).format('YYYY')} ` : ''}${isLeapYear
				? utcMoment().year(2020).dayOfYear(+record.part).format('MMMM DD')
				: utcMoment().dayOfYear(+record.part).format('MMMM DD')}`;
			break;
		// no default
	}
	({ start, end } = resolveInterval({ start, end }, currentFilters));
	return {
		caption, start, end, isPartial,
	};
};

export const transformFiltersBeforeHandleUrl = (filters: BaseParams) => {
	Object.keys(filters).filter((k) => k !== 'timeMin' && k !== 'timeMax').map((k) => {
		if (filters[k] == null) {
			if (k === 'statusIds') {
				filters[k] = [];
			} else {
				filters[k] = undefined;
			}
		}
	});

	return {
		...filters,
		_type: undefined,
		type: undefined,
		id: undefined,
		objectId: undefined,
		column: undefined,
		default: undefined,
		withRows: undefined,
		companyId: undefined,
		current: undefined,
		showSizeChanger: undefined,
		total: undefined,
		offset: undefined,
		count: undefined,
		pageSizeOptions: undefined,
		timeInterval: undefined,
	};
};

// Report source types for values has link to other reports or tables
export const ReportSourceTypeLink = [
	ReportSourceType.Appointment,
	ReportSourceType.Time,
	ReportSourceType.Orders,
	ReportSourceType.Revenue,
	ReportSourceType.Inquiries,
	ReportSourceType.Patient,
	ReportSourceType.Payments,
];

// Report source types for values has relationship with Appointment
export const ReportSourceTypeAppointment = [
	ReportSourceType.Appointment,
	ReportSourceType.Time,
];

// Report source types for values without dollar icon
export const ReportSourceTypeNotDollar = [
	ReportSourceType.Appointment,
	ReportSourceType.Time,
	ReportSourceType.Orders,
	ReportSourceType.Patient,
	ReportSourceType.Inquiries,
	ReportSourceType.Payments,
];

// Report source types for values has relationship with Patient
export const ReportSourceTypePatient = [
	ReportSourceType.Patient,
];

// Report source types for values has relationship with Inquiry
export const ReportSourceTypeInquiry = [
	ReportSourceType.Inquiries,
];

// Report source types for values has link to other reports or tables by order
export const ReportSourceTypeOrderLink = [
	ReportSourceType.Orders,
	ReportSourceType.Revenue,
];

// Report source types for values has relationship with Order
export const ReportSourceTypeOrder = [
	ReportSourceType.Orders,
	ReportSourceType.Revenue,
	ReportSourceType.RevenuePerAppointment,
	ReportSourceType.RevenuePerPatient,
	ReportSourceType.RevenuePerProvider,
	ReportSourceType.Profit,
	ReportSourceType.ProfitPerAppointment,
	ReportSourceType.ProfitPerPatient,
	ReportSourceType.ProfitPerPatient,
	ReportSourceType.ProfitPerProvider,
	ReportSourceType.Commission,
	ReportSourceType.Cost,
];

// Report source types for values work with only appointment time priority
export const ReportSourceTypeAppointmentTime = [
	ReportSourceType.Appointment,
	ReportSourceType.Time,
	ReportSourceType.Patient,
	ReportSourceType.RevenuePerAppointment,
	ReportSourceType.RevenuePerHour,
	ReportSourceType.RevenuePerPatient,
	ReportSourceType.ProfitPerAppointment,
	ReportSourceType.ProfitPerHour,
	ReportSourceType.ProfitPerPatient,
];

export const getReportSourceLocation = (
	sourceType: ReportSourceType,
	overrideParams?: any,
	targetType: TargetType = TargetType.Targets,
	updateParams?: (params) => BaseParams,
	amountPayment?: boolean,
) => {
	const params = getSearchParamsFromUrl(typeof window !== 'undefined' ? window.location : location);

	let path = 'appointment-list';

	if (sourceType === ReportSourceType.Payments) {
		path = 'payments-report';

		if (amountPayment) {
			if (params.reportSourceType) {
				Object.assign(params, {
					reportSourceType: ReportSourceType.Revenue,
				});
			}

			if (params.reportSourceTypes?.length) {
				Object.assign(params, {
					reportSourceTypes: [ReportSourceType.Revenue],
				});
			}
		}
	}

	overrideParams && Object.assign(params, overrideParams);

	Object.assign(params, {
		targetType: params.receivedValue === ReceivedValue.Accrual
			? TargetType.Targets
			: targetType,
	});

	const resParams = updateParams ? updateParams(params) : params;

	const search = appointmentAltFiltersMap.reduce((ac, key) => {
		if (resParams[key] !== undefined) {
			const value = resParams[key];

			if (Array.isArray(value) && value !== null) {
				return `${ac}&${key}=[${value}]`;
			}
			return `${ac}&${key}=${value}`;
		}
		return ac;
	}, '?');

	if (ReportSourceTypeAppointment.includes(sourceType)) {
		path = 'appointment-list';
	} else if (ReportSourceTypePatient.includes(sourceType)) {
		path = 'patient-list';
	} else if (ReportSourceTypeOrderLink.includes(sourceType)) {
		if (resParams.orderType === OrderType.Quote) {
			path = 'quote-list';
		} else if (resParams.orderType === OrderType.Refund) {
			path = 'refund-list';
		} else if (sourceType === ReportSourceType.Revenue) {
			path = 'order-details';
		} else {
			path = 'order-list';
		}
	} else if (ReportSourceTypeInquiry.includes(sourceType)) {
		path = 'inquiry-list';
	}

	return {
		path: `/${path}`,
		search,
	};
};
