import React from 'react';

import { useApplicationContext } from '@common/react/components/Core/Application/Application';
import useFlashingTitle from '@common/react/hooks/useFlashingTitle';

import { Feature } from '@commonTuna/react/objects/CompanyFeature';
import { UserGroupType } from '@commonTuna/react/objects/UserGroup';

import { useCompanyFeatureProviderContext } from '@app/components/UI/CompanyFeaturesSetter/CompanyFeaturesSetter';
import { useUserGroupContext } from '@app/components/UI/UserGroupProvider/UserGroupProvider';
import { User } from '@app/objects/User';

const MessageFlashingTime: React.FC = () => {
	const { checkFeatureAccess } = useCompanyFeatureProviderContext();
	const { checkUserGroupAccess } = useUserGroupContext();
	const { getUser } = useApplicationContext();
	const user = getUser<User>();
	const notViewMC = user?.unviewedMessagesCount || 0;
	const notViewMCS = checkFeatureAccess(Feature.SupportChats) && checkUserGroupAccess(UserGroupType.Support)
		? user?.unviewedSupportMessagesCount || 0 : 0;

	useFlashingTitle({
		title: user && (notViewMC > 0 || notViewMCS > 0)
			? `${notViewMC > 0 ? `+${notViewMC} ✉` : ''}${
				notViewMC > 0 && notViewMCS > 0 ? ', ' : ''}${
				notViewMCS > 0 ? `+${notViewMCS} support ✉` : ''}` : undefined,
	});

	return <></>;
};

export default MessageFlashingTime;
