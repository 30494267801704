import React from 'react';
import { useLocation } from 'react-router-dom';

import loadable from '@loadable/component';

import { loadableDelay } from '@common/react/loadable/loadableSettings';

import Draggable, { DraggablePlace } from '@common/react/components/UI/Draggable';
import { useChatSettingsProviderContext } from '@common/react/components/Chat/ChatSettingsProvider';
import Loader from '@common/react/components/Core/LoadingProvider/Loader';

const Chats = loadable(() =>
	loadableDelay(import(/* webpackChunkName: "Chats" */ '@common/react/components/Chat/Chats')), { fallback: <Loader /> });

interface VideoChatModal {
	defaultPlace?: DraggablePlace;
	dragContainerPadding?: number;
}

const ChatModal: React.FC<VideoChatModal> = (props) => {
	const { defaultPlace = 'leftBottom', dragContainerPadding = 30 } = props;
	const location = useLocation();
	const context = useChatSettingsProviderContext();
	const [fullMode, setFullMode] = React.useState(false);

	if (!context?.state) throw 'need ChatSettingsContext';

	const { state: { modalMode }, actions: { setModalMode } } = context;

	React.useEffect(() => {
		if (!modalMode) {
			setFullMode(false);
		}
	}, [modalMode]);

	React.useEffect(() => {
		if (fullMode) {
			if (typeof document === 'undefined') return;

			const elem = document.body;

			elem.style.overflow = 'hidden';

			return () => {
				elem.style.overflow = 'unset';
			};
		}
	}, [fullMode]);

	React.useEffect(() => {
		if (fullMode) {
			setFullMode(false);
		}
	}, [location.pathname]);

	if (!modalMode) {
		return null;
	}

	return (
		<Draggable
			className={`chat-modal ${fullMode ? 'chat-modal__full' : ''}`}
			isDraggable={!fullMode}
			isInOrigin={false}
			ignoreElements={['btn-video-chat-close', 'btn', 'chat-modal__close', 'chat-modal__full-mode',
				'chat-component', 'chat-component-list-content', 'chat-form-component', 'ant-modal-root',
				'ant-popover', 'ant-dropdown', 'ant-modal', 'ant-tooltip', 'ant-mentions-dropdown', 'ant-select-dropdown']}
			defaultPosition={defaultPlace}
			padding={dragContainerPadding}
		>
			<button className="chat-modal__full-mode" type="button" onClick={() => setFullMode((prev) => !prev)}>
				<i className={`fa fa-window-${fullMode ? 'minimize' : 'maximize'}`} />
			</button>
			<button className="chat-modal__close" type="button" onClick={() => setModalMode(false)}>
				<i className="fa fa-times" />
			</button>
			<div className="chat-modal__inner">
				<Chats />
			</div>
		</Draggable>
	);
};

export default ChatModal;
