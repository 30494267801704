import * as React from 'react';

import Select, { SelectProps } from 'antd/lib/select';
import Empty from 'antd/lib/empty';

import Autocomplete, {
	AutocompleteProps,
	Option,
} from '@common/react/components/Forms/Autocomplete/Autocomplete';
import { isFunction, isUndefined } from '@common/react/utils/guards';
import { WithId } from '@common/typescript/objects/WithId';
import { WithKey } from '@common/react/components/Core/ItemsProvider/ItemsProvider';

const Loading: React.FC = () => {
	return <Empty
		image={Empty.PRESENTED_IMAGE_SIMPLE}
		description="Loading..."
		style={{ marginBlock: 8 }}
		imageStyle={{ height: 35 }}
	/>;
};

export interface MultiSelectAutoProps<T extends WithId = any> extends Omit<AutocompleteProps<T, Partial<SelectProps>>, 'render'> {
	items?: Array<any>;
	loader?: React.ReactNode;
	notFoundContent?: React.ReactNode;
}

const MultiSelectAuto: <T extends WithKey = any>(p: MultiSelectAutoProps<T>) => React.ReactElement<T> = <T extends WithKey, >(
	props: MultiSelectAutoProps<T>,
) => {
	const {
		items,
		loader = <Loading />,
		notFoundContent,
		...rest
	} = props;

	return <Autocomplete
		{...rest}
		items={items || rest.antdProps?.defaultValue}
		render={(props, {
			options,
			loading,
			onChange,
			onSearchHandler,
			onBlur,
			handleChange,
			onFocus,
		}) => {
			const {
				disabled, loaderMarkup, loadOnFocus, placeholder, antdProps,
			} = props;
			let defaultValue = antdProps?.defaultValue as any;

			if (defaultValue && Array.isArray(defaultValue)) {
				defaultValue = defaultValue.map(({ id }) => String(id));
			}

			const onSelect = (value, option) => {
				const selectedValue = rest.getValueFromOption ? rest.getValueFromOption(option)
					: option.item?.id || option.props?.item?.id;

				props.onSelect && props.onSelect(selectedValue, option);

				handleChange('');
			};

			return <div className="autocomplete-filter-component">
				<div className={`autocomplete-component ${disabled ? 'autocomplete-component_disabled' : ''}`}>
					{loading && <div className="autocomplete-component__loader dropdown-not-show">{loaderMarkup}</div>}
					<Select
						{...antdProps}
						allowClear={antdProps?.allowClear ?? true}
						mode="multiple"
						className="pull-right multiple-select-filter"
						notFoundContent={loading ? loader : notFoundContent}
						onChange={onChange}
						disabled={disabled}
						onSelect={onSelect}
						listItemHeight={32}
						onFocus={loadOnFocus ? onFocus : undefined}
						placeholder={isUndefined(placeholder) ? 'Start typing for search...' : placeholder}
						onSearch={onSearchHandler}
						filterOption={(inputValue, { props }: any) => {
							const { children } = props;
							return (typeof children === 'string' && children.toUpperCase().indexOf(inputValue.toUpperCase()) !== -1);
						}}
						defaultValue={defaultValue}
						onBlur={onBlur}
					>
						{options.map((option) => {
							const item = (option?.item || (option as any));
							const { renderTitle } = props;
							let title;
							if (isFunction(renderTitle)) {
								title = renderTitle(item);
							} else {
								title = `${item[(renderTitle || 'name')] || item?.title}`;
							}

							return <Option
								key={option.key || option.id || option.item?.id}
								value={`${option.key || option.id || option.item?.id}`}
								title={title}
								{...{ item }}
							>
								{option.label || option.value || title}
							</Option>;
						})}
					</Select>
				</div>
			</div>;
		}}
	/>;
};

export default MultiSelectAuto;
