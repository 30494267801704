import * as React from 'react';

import { FormikProps } from 'formik';
import Popover from 'antd/lib/popover';
import Input from 'antd/lib/input';
import { TextAreaRef } from 'antd/lib/input/TextArea';
import Select from 'antd/lib/select';
import * as Yup from 'yup';

import {
	ChatFormButtonsProps,
	NewMessage,
	ChatPlugin,
	ChatPlugins,
	Chat,
} from '@common/react/components/Chat/Chat';
import Button from '@common/react/components/Forms/Button';
import { ItemsProvider } from '@common/react/components/Core/ItemsProvider/ItemsProvider';
import Loader from '@common/react/components/Core/LoadingProvider/Loader';
import { WithId } from '@common/typescript/objects/WithId';
import LoadMoreButton from '@common/react/components/Core/ItemsProvider/LoadMoreButton';
import '@common/react/scss/components/templatePlugin.scss';
import SimpleSearchInput from '@common/react/components/Forms/SimpleSearchInput/SimpleSearchInput';
import ItemModalProvider from '@common/react/components/Core/ItemModalProvider/ItemModalProvider';
import { ItemEditor } from '@common/react/components/Core/ItemEditor/ItemEditor';
import FormikField from '@common/react/components/Forms/FormikField/FormikField';
import { simpleStringValidator } from '@common/react/utils/validationHelpers';
import { useChatSettingsProviderContext } from '@common/react/components/Chat/ChatSettingsProvider';
import { BaseUserWithAvatar } from '@common/react/objects/BaseUser';

interface Template extends WithId {
	text: string;
	name: string;
	isFavorite: boolean;
}

interface Data {
	[key: string]: string;
}

export interface TemplateComponentSettings {
	saveRequest: string;
	loadRequest: string;
	getData?: (chat: Chat, user: BaseUserWithAvatar) => Data;
	options: Array<string>;
}

export interface TemplatePlugin extends ChatPlugin {
	options: TemplateComponentSettings;
}

interface TemplateComponentProps {
	chat: Chat;
	updateMessageText: (text) => void;
	getPopupContainer?: (node) => HTMLElement;
}

const validationSchema = Yup.object().shape({
	name: simpleStringValidator,
	text: simpleStringValidator,
});

interface TextareaInnerComponentProps {
	value: string;
	onChange: (item: string) => void;
	options: Array<string>;
}

const Textarea : React.FC<TextareaInnerComponentProps> = ({
	value,
	onChange,
	options,
}) => {
	const ref = React.useRef<TextAreaRef>(null);
	return <div>
		<div>
			<label style={{ lineHeight: '34px' }}>
				Text
			</label>
			<Select
				value={null}
				style={{
					float: 'right',
					width: 150,
				}}
				getPopupContainer={(node) => node.closest('.template-plugin-modal') || document.body}
				placeholder="Templates..."
				onSelect={(value, option) => {
					const textarea = ref.current?.resizableTextArea?.textArea;
					if (textarea && option.value) {
						const template = `{${option.value}}`;
						const value = textarea.value;
						const selectionStart = textarea?.selectionStart;
						const newValue = `${value.slice(0, selectionStart)}${template}${value.slice(selectionStart)}`;
						onChange(newValue);
						if (textarea) {
							const position = textarea.selectionStart + template.length;
							textarea.selectionStart = position;
							textarea.selectionEnd = position;
							textarea.focus();
							setTimeout(() => {
								if (textarea) {
									textarea.selectionStart = position;
									textarea.selectionEnd = position;
								}
							}, 0);
						}
					}
				}}
			>
				{options.map((name) => <Select.Option value={name} key={name}>
					{name}
				</Select.Option>)}
			</Select>
		</div>
		<Input.TextArea
			ref={ref}
			style={{ width: '100%' }}
			value={value}
			onChange={(e) => onChange(e.currentTarget.value)}
			className="form-control"
			autoSize
		/>
	</div>;
};

interface TemplateListProps {
	saveRequest: string;
	loadRequest: string;
	onSelectTemplate: (item: Template) => void;
	setOpen: (flag: boolean) => void;
	options: Array<string>;
}

const TemplateList: React.FC<TemplateListProps> = ({
	saveRequest,
	loadRequest,
	onSelectTemplate,
	setOpen,
	options,
}) => {
	return <div className="">
		<ItemsProvider<Template>
			type="chatTemplate"
			loadRequest={loadRequest}
			skipInitLoad={false}
		>
			{
				({ state: { items, loading, pagination }, actions: { handleChange } }) => {
					return <div>
						<ItemModalProvider
							saveRequest={saveRequest}
							readonly={false}
							getModalProps={(item) => ({
								title: `${item.id > 0 ? 'Edit' : 'Add'} Template`,
								footer: null,
								afterClose: () => setOpen(true),
								rootClassName: 'template-plugin-modal',
							})}
							validationSchema={validationSchema}
							closeAfterSave
							onCloseAfterSave={() => {
								handleChange();
								setOpen(true);
							}}
							render={() => <div
								style={{ marginBottom: -10 }}
							>
								<ItemEditor
									withButtons
									detectChanges
									edit={(formikBag) => <div>
										<div className="row">
											<FormikField
												fieldName="name"
												title="Name*"
												inputProps={{ autoComplete: 'off' }}
												containerClassName="col-sm-12 form-group"
											/>
											<FormikField
												fieldName="text"
												title=""
												containerClassName="col-sm-12 form-group"
												render={({ field }) => <Textarea
													options={options}
													value={field.value}
													onChange={(value) => formikBag.setFieldValue('text', value)}
												/>}
											/>
										</div>
									</div>}
								/>
							</div>}
							type="chatTemplate"
						>
							{(_, context) => <>
								<div className="template-plugin-header">
									<SimpleSearchInput
										withoutForm
										onSubmit={handleChange}
										submitOnClear
										buttonType="button"
									/>
									<Button
										type="button"
										className="btn btn-sm btn-primary"
										onClick={() => {
											context.actions.openModal({
												id: -1,
												text: '',
												name: '',
											});
											setOpen(false);
										}}
									>
										<i className="fa fa-plus" />
									</Button>
								</div>
								<div className="is-relative">
									{items.length > 0 ? <ul className="custom-scroll" style={{ minHeight: 60 }}>
										{items.map((item) => <li
											onClick={() => onSelectTemplate(item)}
											key={item.id}
											title={item.text}
										>
											<span>{item.name}</span>
											<i
												title="Edit"
												onClick={(e) => {
													e.stopPropagation();
													context.actions.openModal(item);
													setOpen(false);
												}}
												className="fa fa-pencil pull-right"
											/>
										</li>)}
									</ul> : null}
									{loading
										? <div className={items.length ? '' : 'template-plugin-loader__wrapper'}>
											<div className="template-plugin-loader"><Loader /></div>
										</div>
										: items.length === 0 && <h4 className="text-center">No templates</h4>
									}
								</div>
							</>}
						</ItemModalProvider>
						{items.length < pagination.total ? <div key="load more" className="text-center load-more">
							<LoadMoreButton type="button" className="btn btn-sm btn-primary">
								Load more
							</LoadMoreButton>
						</div> : null}
					</div>;
				}
			}
		</ItemsProvider>
	</div>;
};

const TemplateComponent: React.FC<TemplateComponentProps> = (props) => {
	const {
		updateMessageText,
		getPopupContainer,
		chat,
	} = props;
	const [open, setOpen] = React.useState(false);
	const context = useChatSettingsProviderContext();
	const user = context?.state?.getUser?.();
	const {
		saveRequest = 'chatTemplate',
		loadRequest = 'chatTemplateList',
		getData = () => ({}),
		options = [],
	} = context?.state?.plugins[ChatPlugins.Template]?.options || {};

	const regexp = new RegExp(`\{(${options.join('|')})\}`, 'g');

	const dictionary = React.useMemo(() => {
		return getData(chat, user);
	}, [chat, getData]);

	return <Popover
		onOpenChange={setOpen}
		open={open}
		getPopupContainer={getPopupContainer}
		trigger="click"
		placement="topRight"
		overlayClassName="template-plugin-popover"
		content={<TemplateList
			onSelectTemplate={(template) => {
				updateMessageText((prev) => {
					const prevText = prev?.trim();
					const insertText = template.text.replace(regexp, (str, key) => {
						return dictionary[key] || `{${key}}`;
					});
					return `${prevText && prevText !== '' ? `${prevText}\n\n` : ''}${insertText}`;
				});
				setOpen(false);
			}}
			setOpen={setOpen}
			saveRequest={saveRequest}
			loadRequest={loadRequest}
			options={options}
		/>}
	>
		<Button
			type="button"
			title="Templates"
			className="btn btn-sm btn-default chat-message-item"
		>
			<i className="fa fa-file-o" />
		</Button>
	</Popover>;
};

export const TemplatePlugin : TemplatePlugin = {
	formButton: (formikBag: FormikProps<NewMessage>, props: ChatFormButtonsProps) =>
		<TemplateComponent
			getPopupContainer={props.getPopupContainer}
			updateMessageText={(text) => formikBag.setValues((prevState) => ({
				...prevState,
				text: typeof text === 'function' ? text(prevState.text) : text,
			}))}
			chat={props.chat}
			{...TemplatePlugin.options}
		/>,
	options: {
		saveRequest: 'chatTemplate',
		loadRequest: 'chatTemplateList',
		options: [],
	},
};
