import * as React from 'react';
import { Route, RouteProps } from 'react-router-dom';
import { Helmet } from 'react-helmet';

import { loadable, loadableDelay } from '@common/react/loadable/loadableSettings';
import { useSizableProviderContext } from '@common/react/components/Core/SizableWithServerPage/SizableWithServerPage';

import { UserGroupType } from '@commonTuna/react/objects/UserGroup';

import { params } from '@app/components/UI/TunaLoader';
import SecureRouteWrapper from '@app/components/Routes/SecureRoute/SecureRouteWrapper';
import { useUserGroupContext } from '@app/components/UI/UserGroupProvider/UserGroupProvider';

const DashboardLayout = loadable(() => loadableDelay(
	/* webpackChunkName: "DashboardLayout" */ import('@app/components/Layouts/DashboardLayout/DashboardLayout'),
), params);

interface Props extends RouteProps {
	component: any;
	redirectPath?: string;
	title?: string;
	dashboard?: boolean;
}

const DashboardRoute: React.FC<Props> = ({
	component: Component, redirectPath = '/', dashboard = true, title, ...rest
}) => {
	const { checkUserGroupAccess } = useUserGroupContext();
	const context = useSizableProviderContext();

	return (
		<Route
			{...rest}
			render={(props) => <SecureRouteWrapper path={rest.path as string} redirectPath={redirectPath}>
				{(checkUserGroupAccess(UserGroupType.DashboardMenuInWizard) || dashboard) && !context.state
					? (
						<DashboardLayout>
							{title || dashboard ? (
								<Helmet>
									<title>{title}</title>
								</Helmet>
							) : null}
							<Component {...props} />
						</DashboardLayout>
					) : <Component {...props} /> }
			</SecureRouteWrapper>}
		/>
	);
};

export default DashboardRoute;
