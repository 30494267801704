import React from 'react';

import Select from 'antd/lib/select';

import { PaymentType, PaymentTypeNames } from '@app/objects/Order';

interface ComponentProps {
	value: Array<any> | undefined;
	onChange: (value) => void;
	className?: string;
	maxTagCount?: number;
	maxTagTextLength?: number;
	allowClear?: boolean;
	filterOption?: boolean;
	excludeTypes?: Array<PaymentType>;
	getPopupContainer?: (node) => HTMLElement;
}

const { Option } = Select;

const MultiplePaymentTypeFilter: React.FC<ComponentProps> = ({
	value,
	onChange,
	className,
	maxTagCount,
	maxTagTextLength,
	allowClear,
	filterOption,
	excludeTypes = [PaymentType.GiftCard, PaymentType.All],
	getPopupContainer,
}) => {
	return (
		<Select
			getPopupContainer={getPopupContainer}
			className={className ?? 'pull-right ml10 multiple-select-filter'}
			mode="multiple"
			placeholder="Payment Types..."
			value={value?.map((q) => q.toString()) || []}
			onChange={(value) => {
				onChange(value);
			}}
			maxTagCount={maxTagCount ?? 2}
			maxTagTextLength={maxTagTextLength ?? 10}
			filterOption={filterOption ?? false}
			allowClear={allowClear ?? true}
		>
			{Object.keys(PaymentTypeNames).filter((q) => !excludeTypes.includes(+q)).map((value) =>
				<Option value={value} key={+value}>{PaymentTypeNames[value]}</Option>)}
		</Select>
	);
};

export default MultiplePaymentTypeFilter;
