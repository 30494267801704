import * as React from 'react';

import once from 'lodash/once';
import Message from 'antd/lib/message';

import ItemsProviderWithStore from '@common/react/components/Core/ItemsProviderWithStore/ItemsProviderWithStore';
import { useItemsProviderContext, SortingDirection } from '@common/react/components/Core/ItemsProvider/ItemsProvider';
import ItemsProviderSynchronizer from '@common/react/components/Core/AdvancedItemsProvider/ItemsProviderSynchronizer';
import { NotificationAction } from '@common/typescript/objects/NotificationAction';

import { UserFavorite } from '@app/objects/User';

export interface UserFavoritesProviderContext {
	state: {
		items: Array<UserFavorite>;
	},
	actions: {
		reload: () => void;
		addFavorite: (url: string, title?: string) => void;
		removeFavorite: (url: string) => void;
	}
}

export const createUserFavoritesProviderContext = once(() => React.createContext({} as UserFavoritesProviderContext));

export const useUserFavoritesProviderContext: () => UserFavoritesProviderContext = () =>
	React.useContext(createUserFavoritesProviderContext());

const showMessage = (message: string, isFailed: boolean = false) => {
	if (!isFailed) {
		Message.success(message, 0.5);
	} else {
		Message.error(message, 0.5);
	}
};

const UserFavoritesProviderInner = ({ children }) => {
	const Context = createUserFavoritesProviderContext();
	const context = useItemsProviderContext<UserFavorite>();
	const {
		state: { items },
		actions: {
			setItems,
			handleChange,
			add,
			save,
		},
	} = context;

	const addFavorite = (url, title) => {
		const isNotExist = items.map((favorite) => favorite.url).indexOf(url) === -1;
		if (isNotExist) {
			const newItem = add({
				url,
				title,
			});
			save(newItem).then((res) => {
				setItems((prev) => prev.map((item) => (item.id === newItem.id ? res : item)));
				showMessage('Favorite is Added');
			});
		}
	};

	const removeFavorite = (url: string) => {
		const id = items.find((userFavorite) => userFavorite.url === url).id;

		save({ id, deleted: true })
			.then(() => {
				setItems((prev) => {
					return prev.filter((userFavorite) => userFavorite.url !== url);
				});
				showMessage('Favorite is Removed');
			});
	};

	const value = {
		state: {
			items,
		},
		actions: {
			reload: () => {
				handleChange();
			},
			addFavorite,
			removeFavorite,
		},
	};

	return <Context.Provider value={value}>
		{children}
	</Context.Provider>;
};

const UserFavoritesProvider: React.FC = ({ children }) => {
	return <ItemsProviderWithStore
		defaultSort={['sortOrder', SortingDirection.Ascending]}
		filterHandler={() => false}
		storeName="userFavorites"
		type="userFavorite"
		pagination={{
			pageSize: 100,
			current: 1,
		}}
		onSaveRequestError={(err) => showMessage(err, true)}
	>
		{() => <>
			<ItemsProviderSynchronizer
				objectType="UserFavorite"
				storeName="userFavorite"
				objectSubtype=" "
				storeSortAfterAdd={(items) => [...items].sort((a, b) => a.sortOrder - b.sortOrder)}
				customHandler={() => {
					return undefined;
				}}
				getItemsFromInit={(notification) => notification.data.userFavorites}
				handle={(notification, context) => {
					if (notification.objectType === 'UserFavorite') {
						const data = notification.data as UserFavorite;
						const userFavorites = context.state.items;

						const isNotExist = userFavorites.map((favorite) => favorite.url).indexOf(data.url) === -1;

						if (notification.action === NotificationAction.Add && isNotExist) {
							context.actions.setItems((prev) => prev.concat(data));
						} else if (notification.action === NotificationAction.Delete) {
							context.actions.setItems((prev) => prev.filter((item) => item.id !== data.id));
						}
					}
				}}
			/>
			<UserFavoritesProviderInner>
				{children}
			</UserFavoritesProviderInner>
		</>}
	</ItemsProviderWithStore>;
};

export default UserFavoritesProvider;
