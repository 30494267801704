import React from 'react';

import Radio from 'antd/lib/radio';
import Select from 'antd/lib/select';

import { useApplicationContext } from '@common/react/components/Core/Application/Application';
import SimpleSearchInput from '@common/react/components/Forms/SimpleSearchInput/SimpleSearchInput';
import SelectFilter from '@common/react/components/Forms/SelectFilter/SelectFilter';
import { BaseParams } from '@common/react/objects/BaseParams';
import FiltersComponent from '@common/react/components/UI/FiltersComponent/FiltersPropsHandler';
import BaseFilterComponent from '@common/react/components/Core/BaseFilterComponent/BaseFilterComponent';

import { CompanyFilter } from '@commonTuna/react/components/UI/CompanyFilter/CompanyFilter';
import { UserGroupTypeNames } from '@commonTuna/react/objects/UserGroup';

import DoctorAutocompleteFilter from '@app/components/UI/DoctorAutocompleteFilter';
import { User, UserRoleNames, UserStatusNames } from '@app/objects/User';
import { getUserStatusDot } from '@app/components/Pages/Admin/Users/Users';
import UserGroupFilter from '@app/components/Pages/Admin/Users/UserGroupFilter';
import LocationSelect from '@app/components/UI/LocationSelect/LocationSelect';

interface Props {
	initialValues?: any;
}

interface RadioFilterProps {
	param: string;
	title: string;
	handleChange: (filters) => void;
	filters: BaseParams;
}

const RadioFilter: React.FC<RadioFilterProps> = ({
	param, title, filters, handleChange,
}) => {
	return (
		<div className="filter-ratio-element">
			<Radio.Group
				name={param}
				value={filters[param] ?? null}
				onChange={(e) => handleChange({ [`${e.target?.name}`]: e.target?.value })}
			>
				<Radio.Button value>{title}</Radio.Button>
				<Radio.Button value={false}>
					Not
					{title}
				</Radio.Button>
				<Radio.Button value={null}>All</Radio.Button>
			</Radio.Group>
		</div>
	);
};

const Option = Select.Option;

const UserFilters: React.FC<Props> = ({ initialValues }) => {
	const { getUser } = useApplicationContext();
	const user = getUser<User>();

	return (
		<BaseFilterComponent render={({
			values, setValues, state: { filters }, actions: { handleChange }, keys, setKeys,
		}) => (
			<div className="list-filters clearfix">
				<div className="list-filters__search filters-wrapper">
					<div className="pull-left">
						<SimpleSearchInput onSubmit={handleChange} name="text" defaultValue={filters.text} withoutForm />
					</div>
				</div>
				<div className="clearfix filters-wrapper">
					<FiltersComponent
						apply
						additionalParams={filters}
						popoverClassName="dashboard-filters-component"
						filtersClean={(clearFilters) => {
							setKeys((prev) => ({ ...prev, companyId: Math.random() }));
							setValues({
								companyName: '',
							});
							handleChange(clearFilters);
						}}
						filtersHandler={{
							showAllUsers: (value) => !!value,
						}}
						popoverStyle={{ width: 'max-content', maxWidth: '80vw' }}
						tooltipValues={(
							<div>
								<div className="filter-element" data-param="companyId">
									<CompanyFilter key={keys?.companyId || 'companyId'} value={values?.companyName ?? ''} />
								</div>
								<RadioFilter handleChange={handleChange} title="Banned" param="banned" filters={filters} />
								{(user?.root || user?.transmutation) && (
									<div className="filter-ratio-element pull-right ml10" data-param="showAllUsers">
										<Radio.Group
											name="showAllUsers"
											defaultValue={false}
											value={filters.showAllUsers}
											onChange={(e) => handleChange({ showAllUsers: e.target?.value })}
										>
											<Radio.Button value>All Companies</Radio.Button>
											<Radio.Button value={false}>My Company</Radio.Button>
										</Radio.Group>
									</div>
								)}
							</div>
						)}
					/>
					<SelectFilter
						additionalParams={filters}
						param="role"
						className="pull-right ml10 mr10"
						onChange={handleChange}
						currentValue={UserRoleNames[filters.role]}
						defaultValue="All Roles"
					>
						<Option value="">All Roles</Option>
						{Object.keys(UserRoleNames).map((key) => (
							<Option value={key} key={key}>{UserRoleNames[key]}</Option>
						))}
					</SelectFilter>
					<UserGroupFilter
						additionalParams={filters}
						param="userGroupType"
						className="pull-right ml10"
						onChange={handleChange}
						currentValue={UserGroupTypeNames[filters.userGroupType]}
						defaultValue="All flags"
					/>
					<div className="pull-right ml10">
						<DoctorAutocompleteFilter
							handleChangeAndRefresh={handleChange}
							param="doctorId"
							value={values?.doctorName}
							params={{
								provider: true,
							}}
						/>
					</div>
					<div className="pull-right ml10">
						<LocationSelect params={filters} onChange={handleChange} locations={initialValues?.locations} />
					</div>
					<Select
						className="pull-right ml10"
						value={filters.statuses ? +filters.statuses[0] : null}
						onChange={(value) => handleChange({ statuses: value !== null ? [value] : undefined })}
					>
						<Option key="All" value={null as any}>All Statuses</Option>
						{
							Object.keys(UserStatusNames).map((status) => (
								<Option key={status} value={+status}>
									{getUserStatusDot(+status)}
									<span className="ml10">{UserStatusNames[status]}</span>
								</Option>
							))
						}
					</Select>
				</div>
			</div>
		)}
		/>
	);
};

export default UserFilters;
