import React from 'react';

import moment from 'moment/moment';

import Button from 'antd/lib/button';
import Message from 'antd/lib/message';
import Tag from 'antd/lib/tag';
import Tooltip from 'antd/lib/tooltip';

import { useApplicationContext } from '@common/react/components/Core/Application/Application';
import { File as FileInterface } from '@common/typescript/objects/FileInterface';
import Colorbox from '@common/react/components/UI/Colorbox/Colorbox';
import { getUserName } from '@common/react/utils/utils';
import LinkWithPrevLocation from '@common/react/components/UI/LinkWithPrevLocation/LinkWithPrevLocation';

import { dateTimeFormatString } from '@app/components/Utils';
import ReadOnlyControlValue from '@app/components/Forms/ReadOnlyControlValue/ReadOnlyControlValue';
import { AppointmentEmailStatus } from '@app/objects/AppointmentEmail';

export const extensions = ['jpg', 'jpeg', 'png', 'gif', 'heic', 'webp', 'svg', 'avif'];
export const messageKey = 'send-email-message-key';

const SendEmailReadonly: React.FC<{ item, patientName, setOpen, setItem, setChanged, save }> = (props) => {
	const {
		item,
		patientName: propsPatientName,
		setOpen,
		setItem,
		setChanged,
		save,
	} = props;

	const { request } = useApplicationContext();

	const [img, setImg] = React.useState<FileInterface | null>(null);
	const sender = item?.from || 'support@northerntuna.com';
	const isInbound = item?.appointmentEmailStatus === AppointmentEmailStatus.Inbound;
	const patientName = item?.patient ? `${item.patient?.firstName} ${item.patient.lastName}`
		: propsPatientName;
	const files = item?.patientFiles || [];
	const photos = React.useMemo(() => {
		return (item?.patientFiles || []).filter((f) => extensions.includes(f.file?.extension?.toLowerCase()));
	}, [files]);
	const imageIndex = React.useMemo(() => {
		const index = photos.findIndex((image) => image.file?.id === img?.file?.id);
		return index < 0 ? null : index;
	}, [photos, img]);

	const toggleRead = () => {
		Message.loading({ content: 'sending', key: messageKey, duration: 10 });
		request('readEmail', {
			id: item.id,
			read: !item.read,
		})
			.then(() => {
				setItem({ ...item, read: !item.read });
				setChanged(true);
				Message.success({ content: 'Saved', key: messageKey });
			})
			.catch(() => {
				Message.error('Read item failed.');
			});
	};

	const tag = (
		<div style={{ verticalAlign: 'middle' }}>
			{item && (
				<Tag
					color="geekblue"
					style={{ margin: '3px 0' }}
				>
					<i className="fa fa-calendar-plus-o" style={{ marginRight: 5 }} />
					{moment(item.time).format(dateTimeFormatString)}
				</Tag>
			)}
		</div>
	);

	const newTab = (
		<div className="pull-right" style={{ height: 0 }}>
			{item && (
				<Tag
					color="geekblue"
					style={{ marginRight: 0 }}
				>
					<LinkWithPrevLocation
						to={{ pathname: `/appointmentEmailView/?appointmentEmailId=${item.id}`, search: '' }}
						target="_blank"
					>
						View at new tab
					</LinkWithPrevLocation>
				</Tag>
			)}
		</div>
	);

	return <>
		<div className="clearfix">
			<div
				style={{
					margin: '-6px 15px 15px 0', lineHeight: '34px', display: 'flex', flexDirection: 'row', gap: 15,
				}}
			>
				<div
					style={{
						fontSize: 14, lineHeight: 1.5, flex: '1 1 auto', width: '1px',
					}}
				>
					<strong>Sender</strong>
					<br />
					<div className="ellipsis">
						<Tooltip title={sender} color="white" placement="topLeft">
							<span>
								{sender}
							</span>
						</Tooltip>
						{isInbound && patientName && (
							<LinkWithPrevLocation
								className="ml10 ellipsis"
								to={{ pathname: `/patient-editor/${item.patient?.id}` }}
							>
								{patientName}
							</LinkWithPrevLocation>
						)}
						{!isInbound && item.user && (
							<LinkWithPrevLocation
								className="ml10 ellipsis"
								to={{ pathname: `/user-editor/${item.user.id}`, search: 'mode=view' }}
								target="_blank"
							>
								{getUserName(item.user)}
							</LinkWithPrevLocation>
						)}
					</div>
				</div>
				{tag}
				<div className="appointment-popup email-popup-menu">
					{isInbound && <span
						className={`appointment-popup__top-icon ${item.read ? 'text-muted' : ''}`}
						title={`${item.read ? 'Set this email as Unread' : 'set this email as Read'}`}
						onClick={() => toggleRead()}
					>
						<i className="fa fa-eye" />
					</span>}
					<span
						className={`appointment-popup__top-icon ${item.archive ? '' : 'text-muted'}`}
						title={`${item.archive ? 'Make this email Unarchived' : 'Make this email Archived'}`}
						onClick={() => {
							save({ ...item, archive: !item.archive }, true, 'appointmentEmail')
								.then(() => setChanged(true));
						}}
					>
						<i className="fa fa-archive" />
					</span>
				</div>
			</div>
		</div>
		<div
			className="send-email-modal__form custom-scroll send-email-modal__readonly send-email-modal__view"
		>
			<ReadOnlyControlValue
				className="form-group"
				title="To"
				value={<>
					{item.to}
					{!isInbound && item.inquiryId && (
						<LinkWithPrevLocation
							className="ml10 ellipsis"
							to={{ pathname: `/inquiry-editor/${item.inquiryId}` }}
						>
							<Tag color="cyan">Inquiry</Tag>
						</LinkWithPrevLocation>
					)}
					{!isInbound && patientName && (
						<LinkWithPrevLocation
							className="ml10 ellipsis"
							to={{ pathname: `/patient-editor/${item.patient?.id}` }}
						>
							{patientName}
						</LinkWithPrevLocation>
					)}
				</>}
			/>
			<ReadOnlyControlValue
				className="form-group"
				title="Subject"
				value={item.subject}
			/>
			{newTab}
			<ReadOnlyControlValue
				className={`simple-text ${files.length ? 'form-group' : ''}`}
				title="Content"
				value={<div
					// eslint-disable-next-line
					dangerouslySetInnerHTML={{ __html: item.html || item.text || '-' }}
					className="email-text form-group form-group__bordered"
					style={{ marginBottom: 0 }}
				/>}
			/>
			<Colorbox
				files={photos || []}
				defaultIdx={imageIndex ?? null}
				onCancel={() => setImg(null)}
				zIndex={1002}
			/>
			{!!files.length && (
				<div>
					{files.map((a, i) => {
						const isImage = extensions.includes(a.file?.extension?.toLowerCase());
						/* eslint-disable */
						return (
							<a
								href={isImage ? undefined : a.file?.src}
								className="attached-file mr10 mb10 clearfix attached-file__readonly"
								key={a.id}
								download={!isImage}
								{...(isImage ? {} : { target: '_blank', rel: 'noreferrer' })}
								onClick={(e) => {
									if (!isImage) return;
									e.preventDefault();
									e.stopPropagation();
									setImg(a);
								}}
							>
								{isImage ? (
									<div
										className="attached-file__image"
									>
										<img
											className="chat-file-tag-image"
											src={a.file?.src}
											alt={a.file?.name}
										/>
									</div>
								) : <i className="fa fa-file" />}
								&nbsp;
								{a.file?.name}
							</a>
						);
						/* eslint-enable */
					})}
				</div>
			)}
		</div>
		<div className="clearfix text-center">
			<Button
				key="send"
				className="btn btn-primary"
				style={{ color: 'white' }}
				onClick={() => setOpen(false)}
			>
				Close
			</Button>
		</div>
	</>;
};

export default SendEmailReadonly;
