/**
 * ## EditableTable.tsx ##
 * This file contains EditableTable component
 * @packageDocumentation
 */

import * as React from 'react';

import Table, { ColumnProps, TableProps } from 'antd/lib/table';
import Checkbox from 'antd/lib/checkbox';
import Dropdown from 'antd/lib/dropdown';
import Tooltip, { TooltipPlacement } from 'antd/lib/tooltip';
import { ColumnType, TableRowSelection } from 'antd/lib/table/interface';
import Pagination, { PaginationProps } from 'antd/lib/pagination';
import Spin from 'antd/lib/spin';
import { PopoverProps } from 'antd/lib/popover';

import { deleteConfirmation } from '@common/react/components/Modal/Modal';
import Button from '@common/react/components/Forms/Button';
import {
	useItemsProviderContext,
	WithKey,
	SortingDirection,
	ColumnFilter,
} from '@common/react/components/Core/ItemsProvider/ItemsProvider';
import CheckboxList from '@common/react/components/Core/CheckboxList/CheckboxList';

import '@common/react/scss/components/editableTable.scss';
import CoreLoader from '@common/react/components/Core/LoadingProvider/Loader';
import ResizeObserverContainer from '@common/react/components/UI/ResizeObserverContainer/ResizeObserverContainer';
import TableButtons from '@common/react/components/UI/TableButtons/TableButtons';
import { useItemModalProviderContext } from '@common/react/components/Core/ItemModalProvider/ItemModalProvider';

export const defaultPageSizeOptions = [10, 20, 30, 40];

/**
 * This is function type. Used to update item edit state.
 * The function accepts two argument - key and value.
 * key - property to be edited.
 * value - new value
 */
export type SetValueCallback = (key: string, value) => void;
/**
 * This is function type. Used to render field with validation wrapper.
 * The function accepts four argument - key, node, inputType and inputProps.
 * key - property to be edited.
 * node - custom field node.
 * inputType - input type property.
 * inputProps
 */
export type ValidationWrapperCallback = (
	key: string,
	node?: React.ReactNode,
	inputType?: string,
	inputProps?: React.HTMLProps<HTMLInputElement>
) => React.ReactNode;

export enum ColumnActions {
	Custom,
	Cancel,
	Remove,
	Edit,
	Save,
	Drag,
	Modal,
}

type EditableTablePaginationProps =
	Omit<PaginationProps, 'current' | 'pageSize' | 'itemRender' | 'onChange' | 'onShowSizeChange'>;

/**
 * @interface ViewColumnProps
 * @typeParam T - T Any WithKey entity
 */
export interface ViewColumnProps<T extends WithKey = any> {
	/**
	 * row index
	 */
	index: number;
	/**
	 * getDefaultAction - used for rendering actions
	 * @param actions - action array
	 * @returns ReactNode
	 */
	getDefaultAction: (actions: Array<ColumnActions>) => React.ReactNode;
	/**
	 * save - call save request without validation
	 * @param item - new item values
	 * @param saveRequest - request name
	 * @returns ReactNode
	 */
	save: (item?: T, saveRequest?: string) => void;
	/**
	 * current item loading state
	 */
	loading: boolean;
	/**
	 * update - change item state without request
	 * @param item - new item values
	 */
	update: (item: Partial<T>) => void;
	/**
	 * updateAndSave - call save request with validation
	 * @param item - new item values
	 * @param saveRequest - request name
	 * @returns ReactNode
	 */
	updateAndSave: (item: Partial<T>, saveRequest?: string) => void;
	/**
	 * reload - refresh table items
	 */
	reload: () => void;
	/**
	 * callback to change current row expanded state
	 */
	setExpanded: (value: boolean) => void;
}

export interface EditColumnProps<T extends WithKey = any> extends ViewColumnProps<T> {
	/**
	 * setValue - used to update item edit state
	 */
	setValue: SetValueCallback;
	/**
	 * validationWrapper - return default field with validation wrapper
	 */
	validationWrapper: ValidationWrapperCallback;
	/**
	 * add - add new item
	 * @param item - new item values
	 */
	add: (item?: Partial<T>) => void;
	/**
	 * expand - change item expand state
	 * @param value - expand value
	 */
	expand: (value: boolean) => void;
}

export type EditableTableActon<Props, T extends WithKey = any> = ColumnActions
	| ((item: T, props: Props, { handleDelete, isDisabled, Loader }) => any);

export type EditableTableActionsType<Props, T extends WithKey = any> = ((prev: Array<ColumnActions>) => Array<EditableTableActon<Props, T>>)
	| Array<EditableTableActon<Props, T>>;

export interface EditableTableColumn<T extends WithKey> extends Omit<ColumnProps<T>, 'render' | 'children'> {
	/**
	 * view - cell render function in view mode
	 * @param text - cell value - item[dataIndex]
	 * @param record - row item
	 * @param props - ViewColumnProps
	 * @returns ReactNode
	 */
	view: (text: string, item: T, props: ViewColumnProps) => React.ReactNode | void;
	/**
	 * view - cell render function in edit mode
	 * @param text - cell value - item[dataIndex]
	 * @param record - row item
	 * @param props - EditColumnProps
	 * @returns ReactNode
	 */
	edit?: (text: string, item: T, props: EditColumnProps) => React.ReactNode | void;
	/**
	 * default state for settings
	 */
	hide?: boolean;
	/**
	 * have access to view the column. Default true
	 */
	enable?: boolean;
	/**
	 * custom settings title
	 */
	settingsTitle?: React.ReactNode;
	/**
	 * column name for mobile view
	 */
	mobileTitle?: string;
	/**
	 * view - custom render in mobile view in view mode
	 * @param text - cell value - item[dataIndex]
	 * @param record - row item
	 * @param props - ViewColumnProps
	 * @returns ReactNode
	 */
	mobileView?: (text: string, item: T, props: ViewColumnProps) => React.ReactNode | void;
	/**
	 * view - custom render in mobile view in edit mode
	 * @param text - cell value - item[dataIndex]
	 * @param record - row item
	 * @param props - EditColumnProps
	 * @returns ReactNode
	 */
	mobileEdit?: (text: string, item: T, props: EditColumnProps) => React.ReactNode | void;
	/**
	 * hide checkbox in columnSettings
	 */
	hideCheckbox?: boolean;
	children?: Array<EditableTableColumn<T>>;
}

export interface CustomTableRowSelection<T> extends Omit<TableRowSelection<T>, 'selections'> {
	selections?: Array<SelectionsOptions>;
}

interface ExpandRowProps<T> {
	item: T,
	readonly: boolean,
	setValue: (key: string, value) => void,
}

export interface EditableTableProps<T extends WithKey> {
	/**
	 * column array
	 * @interface EditableTableColumn[]
	 * @typeParam T - T Any WithKey entity
	 */
	columns: Array<EditableTableColumn<T>>;
	/**
	 * if true show modal confirmation before remove item
	 */
	removeConfirmation?: boolean;
	/**
	 * custom deletion confirmation message
	 */
	removeConfirmationText?: string;
	/**
	 * show border on not. default true
	 */
	bordered?: boolean;
	/**
	 * rowClassName - get row className
	 * @param record - row value
	 * @param index - row index
	 * @returns string
	 */
	rowClassName?: (record: T, index: number) => string | undefined;
	/**
	 * --- if you pass a function, it should return ReactNode.
	 * --- if boolean - show or hide default button.
	 * --- if string - show default button with this string
	 */
	addButton?: React.ReactNode | string | boolean;
	/**
	 * view mode
	 */
	readonly?: boolean;
	/**
	 * expandedRowRender - render row expanded row
	 * @param ExpandRowProps - {item, setValue, readonly}
	 * @param index - row index
	 * @param indent - indent value
	 * @param expanded - row expand state
	 * @returns string
	 */
	expandedRowRender?: ((expandRowProps: ExpandRowProps<T>, index, indent, expanded) => React.ReactNode);
	/**
	 * expandIcon - custom expand icon
	 * @param props - antd props
	 * @param item - row data
	 * @returns ReactNode
	 */
	expandIcon?: (props, item) => React.ReactNode;
	/**
	 * expandShow - show expand or not
	 * @param item - row data
	 * @returns ReactNode
	 */
	expandShow?: (item: T, expandedRows: Array<string>) => boolean;
	/**
	 * expandIcon - show expand icon condition
	 * @param props - antd props
	 * @param item - row data
	 * @returns boolean
	 */
	showExpandIcon?: ((props, item) => boolean);
	/**
	 * prevent request after change pagination or sorting
	 */
	localReload?: boolean;
	/**
	 * prevent request when delete item
	 */
	localRemove?: boolean;
	/**
	 * view actions array.
	 * array of functions or ColumnActions
	 *
	 * if function have two arguments - item: row value, props: ViewColumnProps
	 *
	 * you can use built-in buttons by passing ColumnActions
	 * - [ColumnActions.Edit, ColumnActions.Remove]
	 *
	 * if you need custom buttons, use the function
	 * - [(item, props, {handleDelete, isDisabled, Loader}) => React.ReactNode]
	 *
	 * or both
	 * - [ColumnActions.Edit, (item, props, {handleDelete, isDisabled, Loader}) => React.ReactNode]
	 *
	 * or if you need default buttons and some custom use like this
	 * - (defaultActions) => [...defaultActions, (item, props, {handleDelete, isDisabled, Loader}) => React.ReactNode]
	 */
	viewActions?: EditableTableActionsType<ViewColumnProps>;
	/**
	 * edit actions array.
	 *
	 *
	 * array of functions or ColumnActions
	 * if function have two arguments - item: row value, props: ViewColumnProps
	 *
	 * you can use built-in buttons by passing ColumnActions
	 * - [ColumnActions.Edit, ColumnActions.Remove]
	 *
	 * if you need custom buttons, use the function
	 * - [(item, props, {handleDelete, isDisabled, Loader}) => React.ReactNode]
	 *
	 * or both
	 * - [ColumnActions.Edit, (item, props, {handleDelete, isDisabled, Loader}) => React.ReactNode]
	 *
	 * or if you need default buttons and some custom use like this
	 * - (defaultActions) => [...defaultActions, (item, props, {handleDelete, isDisabled, Loader}) => React.ReactNode]
	 */
	editActions?: EditableTableActionsType<EditColumnProps>;
	/**
	 * use TableButtons or not. if true custom each function should return one dom element.
	 */
	adaptiveActions?: boolean;
	/**
	 * min actions column width
	 */
	actionColumnWidth?: string;
	/**
	 * custom action settings title
	 */
	actionSettingsTitle?: React.ReactNode;
	/**
	 * hide table pagination. Default false
	 */
	hidePagination?: boolean;
	/**
	 * prevent init load
	 */
	skipInitLoad?: boolean;
	/**
	 * show 'select row' column
	 */
	withSelectedRow?: boolean;
	/**
	 * antd selectionRow props
	 */
	selectionRow?: CustomTableRowSelection<T>;
	/**
	 * antd TooltipPlacement property. Used in SelectionRow tooltip
	 */
	selectionTooltipPlacement?: TooltipPlacement;
	/**
	 * buttons inside SelectionRow tooltip
	 */
	columnTitleButtons?: (selectedItems: Array<T>, setSelectedItems?: (items: Array<T>) => void) => React.ReactNode;
	/**
	 * actions column className
	 */
	actionsClassName?: string;
	/**
	 * hide SelectedRow delete action
	 */
	withoutSelectedDelete?: boolean;
	/**
	 * custom antd props
	 */
	antdProps?: TableProps<T>;
	/**
	 * show column settings. Default true
	 */
	withColumnSettings?: boolean;
	/**
	 * defaultColumnSettings - get default column settings. string array or function.
	 * if function accepts one argument - column data
	 */
	defaultColumnSettings?: ((array: Array<EditableTableColumn<T>>) => Array<string | never>) | Array<string>;
	/**
	 * custom table loader
	 */
	loader?: React.ReactNode;
	/**
	 * show only saved items in view mode
	 */
	onlySavedItems?: boolean;
	/**
	 * removeConfirmationContainer - get confirmation modal container
	 * @param instance - trigger html node
	 */
	removeConfirmationContainer?: (instance?: React.ReactInstance) => HTMLElement;
	/**
	 * onEdit - item edit callback
	 * @param instance - trigger html node
	 */
	onEdit?: (item: T) => void;
	/**
	 * use mobileView. by default true
	 */
	withMobileView?: boolean;
	/**
	 * renderMobile - custom render mobile view
	 * @param caption - column name
	 * @param text - cell value
	 */
	renderMobile?: typeof renderMobileCell;
	/**
	 * onColumnSettingsChange - column settings change callback
	 * @param columnSettings - new column settings
	 */
	onColumnSettingsChange?: (columnSettings) => void;
	/**
	 * table id. used for tests
	 */
	tableId?: string;
	/**
	 * reset columnSettings after changing number of columns
	 */
	resetColumnSettings?: boolean;
	/**
	 * sync columnSettings. overwrite table columnSettings
	 */
	columnSettings?: Array<string>;
	/*
	* show Clear All link. required deleteAllRequest at ItemsProvider
	 */
	withClearAll?: boolean;
	/**
	 * show items total count
	 */
	withTotal?: boolean;
	/**
	 * antd props for action column
	 */
	actionColumnProps?: Omit<ColumnProps<T>, 'render' | 'title' | 'dataIndex' | 'children'>;
	/**
	 * antd props for column settings popover
	 */
	columnSettingsPopoverProps?: PopoverProps;
	/**
	 * antd pagination props
	 */
	paginationProps?: EditableTablePaginationProps;
	/**
	 * custom error message
	 * @param error - error message
	 * @param reload - load data callback
	 */
	customError?: (error, reload) => React.ReactNode;
	/**
	 * custom row key
	 * @param item - current row data
	 * @param type - itemsProvider type property
	 */
	getKey?: (item: T & {keyGUID?: string}, type: string) => string;
	/**
	 * a function that is called after the table has been resized
	 * @param props - data about the new table size
	 */
	onResize?: (props: { width: number, height: number }) => void;
	/**
	* prevent reload after delete
	* */
	preventReloadAfterDelete?: boolean;
}

const emptyColumns = [{
	key: '-',
	title: '-',
	render: () => '-',
}];

const defaultError = (error, reload) => <div className="editable-table-error">
	<div>Ops something goes wrong...</div>
	<a onClick={(e) => {
		e.preventDefault();
		reload();
	}}
	>
		try load again
	</a>
</div> as React.ReactNode;

/**
 * getSortOrder - return column sorting
 * @param column - {caption, direction}
 * @param dataIndex - column sorting name
 * @returns res
 */
const getSortOrder = (column, dataIndex): 'ascend' | 'descend' | undefined => {
	const sorter = column?.find(({ caption }) => caption === dataIndex);

	return sorter
		? +sorter.direction === SortingDirection.Descending ? 'descend' : 'ascend'
		: undefined;
};

/**
 * renderMobileCell - return cell markup for mobile
 * @param caption - column name
 * @param text - cell value
 * @param fullWidth - use full cell width
 * @returns res
 */
export const renderMobileCell = (caption: React.ReactNode, text: string | JSX.Element | null | Array<string>, fullWidth = false): JSX.Element => {
	return <>
		<div className={`table-mobile__caption ${fullWidth ? 'table-mobile__caption_full' : ''}`}>{caption}</div>
		<div className={`table-mobile__content ${fullWidth ? 'table-mobile__content_full' : ''}`}>{text}</div>
	</>;
};

/**
 * getDefaultColumnSettings - filter columns by !hide and return dataIndex from column
 * @param columns - table columns
 * @returns res
 */
export const getDefaultColumnSettings = (columns) => columns.filter(({ hide }) => !hide).map(({ dataIndex }) => dataIndex);

const itemRender = (tableId: string) => (_, type, originalElement) => {
	if (type === 'prev') {
		return <div data-pagination={`prev-${tableId}`} style={{ height: '100%' }}>{originalElement}</div>;
	}
	if (type === 'next') {
		return <div data-pagination={`next-${tableId}`} style={{ height: '100%' }}>{originalElement}</div>;
	}
	return originalElement;
};

interface SelectionsOptions {
	key: string;
	label: string;
	onSelect: (items, selectedRows, setSelectedRows) => void;
}

const defaultSelections = [
	{
		key: 'all',
		label: 'Select all data',
		onSelect: (items, selectedRows, setSelectedRows) => {
			setSelectedRows(items);
		},
	},
	{
		key: 'invert',
		label: 'Invert current page',
		onSelect: (items, selectedRows, setSelectedRows) => {
			setSelectedRows(items.filter((item) => !selectedRows.find((row) => row.id === item.id)));
		},
	},
	{
		key: 'none',
		label: 'Clear all data',
		onSelect: (items, selectedRows, setSelectedRows) => {
			setSelectedRows([]);
		},
	},
	{
		key: 'odd',
		label: 'Select Odd Row',
		onSelect: (items, selectedRows, setSelectedRows) => {
			setSelectedRows(items.filter((_, index) => {
				return index % 2 === 0;
			}));
		},
	},
	{
		key: 'even',
		label: 'Select Even Row',
		onSelect: (items, selectedRows, setSelectedRows) => {
			setSelectedRows(items.filter((_, index) => {
				return index % 2 !== 0;
			}));
		},
	},
];

const defaultGetKey = (item, type) => `${type}-${item.id}`;

/**
 * getKeyWithGUID - return row key
 * @param item - row item
 * @param type - ItemsProvider type
 * @returns res
 */
export const getKeyWithGUID = (item, type) => `${type}-${item.id}-${item?.keyGUID || ''}`;

/**
 * EditableTable component.
 * - need fontawesome icon styles and $width-sm variable at '@app/scss/utils/variables.scss'
 *
 * @typeParam T - T Any {WithKey}
 * @param props - EditableTableProps<T>
 * @type {React.FC<EditableTableProps>}
 * @returns React.ReactElement
 */
export const EditableTable: <T extends WithKey>(p: EditableTableProps<T>) => React.ReactElement<T> = <T extends WithKey, >(
	props,
) => {
	const {
		columns,
		removeConfirmation = true,
		bordered = true,
		rowClassName,
		addButton = true,
		readonly = false,
		expandedRowRender,
		expandIcon,
		expandShow: expandShowProps,
		showExpandIcon = (props, item) => true,
		localReload = false,
		localRemove = false,
		viewActions = ((p) => p) as EditableTableActionsType<ViewColumnProps>,
		editActions = ((p) => p) as EditableTableActionsType<EditColumnProps>,
		actionColumnWidth = '',
		hidePagination = false,
		skipInitLoad = false,
		withSelectedRow = false,
		selectionRow = {},
		columnTitleButtons,
		actionsClassName = '',
		withoutSelectedDelete = false,
		antdProps = {},
		selectionTooltipPlacement = 'top',
		withColumnSettings = true,
		defaultColumnSettings = getDefaultColumnSettings,
		removeConfirmationText = '',
		removeConfirmationContainer,
		loader: loaderFromProps = '',
		onlySavedItems = true,
		adaptiveActions = false,
		actionSettingsTitle = 'Actions',
		onEdit = (item) => item,
		withMobileView = true,
		renderMobile = renderMobileCell,
		onColumnSettingsChange,
		tableId = '',
		resetColumnSettings = true,
		withTotal = false,
		withClearAll = false,
		columnSettings: propsColumnSettings,
		columnSettingsPopoverProps,
		actionColumnProps = {},
		paginationProps = {} as EditableTablePaginationProps,
		customError = defaultError,
		getKey: getKeyProp = defaultGetKey,
		onResize,
		preventReloadAfterDelete,
	} = props;
	const showSizeChanger = (paginationProps.showSizeChanger ?? true);
	const pageSizeOptions = paginationProps.pageSizeOptions || defaultPageSizeOptions;
	const _viewActions = typeof viewActions === 'function' ? viewActions([ColumnActions.Edit, ColumnActions.Remove]) : viewActions;
	const _editActions = typeof editActions === 'function' ? editActions([ColumnActions.Cancel, ColumnActions.Save]) : editActions;

	const [afterLoadAdd, setAfterLoadAdd] = React.useState(false);
	const [expandedRows, setExpandedRows] = React.useState<Array<string>>([]);

	const context = useItemsProviderContext<T>();

	if (!context.state) throw 'Need ItemsProvider context!';

	const {
		state: {
			items, advancedItems, loading, pagination, edits, errors, loaders, multiple, type, selectedRows, filters, addedFirst, transformAfterSave,
			deleting, deleteAllRequest, error,
		},
		actions: {
			add, save, update, setEdits, setEdit, reload, setSelectedRows, deleteItems, setItems, saveItems, setLoading, validateAll,
			deleteAll, load,
		},
	} = context;

	const modalContext = useItemModalProviderContext<T>();

	const loader = loaderFromProps || <CoreLoader defaultLoader={<Spin />} />;

	const editCount = Object.keys(edits).length;
	React.useEffect(() => {
		if (editCount > 0 && selectedRows.length) {
			setSelectedRows([]);
		}
	}, [editCount]);
	const getKey = React.useMemo(() => (item) => getKeyProp(item, type), [type]);

	const expandShow = (item, expandedRows) => {
		return expandShowProps ? expandShowProps(item, expandedRows) : expandedRows.includes(getKey(item));
	};

	const handleCheckedChange = () => {
		setSelectedRows(selectedRows.length !== items.length ? items : []);
	};

	const handleDeleteItems = () => {
		deleteConfirmation(
			() => {
				deleteItems(
					selectedRows,
					preventReloadAfterDelete
						? () => Promise.resolve({ list: items, count: items.length } as any)
						: undefined,
				);
			},
			'Selected items will be deleted. Are you Sure?',
			removeConfirmationContainer,
			{
				okButtonProps: {
					'data-confirm': 'all',
				},
			},
		);
	};

	const rowSelection = {
		onChange: (selectedRowKeys, selectedRows) => {
			setSelectedRows(selectedRows);
		},
		selectedRowKeys: selectedRows.map(getKey),
		columnTitle: <div className="selected-items__actions">
			<Tooltip
				open={selectedRows.length > 0 && !editCount && !!(columnTitleButtons || !withoutSelectedDelete)}
				placement={selectionTooltipPlacement}
				getTooltipContainer={(node) => node.closest('.ant-table') || document.body}
				title={<>
					{withoutSelectedDelete ? null : <Button
						key="remove"
						className="btn btn-sm btn-danger"
						type="button"
						disabled={selectedRows.length === 0}
						title="Delete Selected Rows"
						onClick={handleDeleteItems}
					>
						<i className="fa fa-trash" />
					</Button>}
					{columnTitleButtons && columnTitleButtons(selectedRows, setSelectedRows)}
				</>}
			>
				<Checkbox
					disabled={!!editCount}
					indeterminate={selectedRows.length > 0 && selectedRows.length < items.length}
					checked={selectedRows.length === items.length}
					onChange={handleCheckedChange}
				/>
			</Tooltip>
			<div className="ant-table-selection-extra">
				<Dropdown
					disabled={!!editCount}
					menu={{
						items: ((selectionRow as CustomTableRowSelection<T>)?.selections || defaultSelections)
							.map((item) => ({
								...item,
								onSelect: undefined,
								onClick: () => item.onSelect(items, selectedRows, setSelectedRows),
							})),
					}}
				>
					<i className="fa fa-angle-down" />
				</Dropdown>
			</div>
		</div>,
		placement: 'left',
		...selectionRow,
		getCheckboxProps: editCount > 0
			? (...rest) => ({ ...selectionRow.getCheckboxProps?.(...rest), disabled: true }) : selectionRow.getCheckboxProps,
	};

	const onChange = (pagination) => {
		!localReload && reload(pagination)
			.then((res) => {
				deleting.current = false;
			});
	};

	const CanChange = () => {
		return multiple || editCount === 0;
	};

	const Loader = (record) => {
		return loaders[record.id];
	};

	const Edit = (record) => {
		return readonly ? undefined : edits[record.id] as T;
	};

	const Error = (record, path) => {
		const item = errors[record.id]?.err;
		return typeof item !== 'undefined' ? item[path] : null;
	};

	const clearEdit = (id) => {
		const temp = { ...edits };
		delete temp[id];
		setEdits(temp);
	};

	const setItemPropValue = (id: number, propName: string, value: any) => {
		if (typeof value === 'function') {
			edits[id] = { ...edits[id], [propName]: value(edits[id][propName]) };
		} else {
			edits[id] = { ...edits[id], [propName]: value };
		}
		onEdit && onEdit(edits[id]);
		setEdit(edits[id]);
		validateAll(false);
	};

	const deleteFromArray = (index) => {
		const t = [...(advancedItems || items)];
		t.splice(index, 1);
		update(t);
		// setItems(t);
	};

	const saveInArray = (record, response, resetEdits?: boolean) => {
		const id = record.id;

		update((advancedItems || items).map((q: T) => (q.id === id
			? transformAfterSave(q, { ...edits[id], id: response.id }, response) : q)), resetEdits);
		// setItems(_items.map(q => q.id === record.id ? { ...q, ...edits[record.id] } : q));

		clearEdit(id);

		// setEdits({ ...edits, [record.id]: undefined });
	};

	const saveItem = (record, skipValidation?: boolean, saveRequest?: string) => {
		if (record.deleted) {
			deleting.current = true;
		}
		save(record, skipValidation, saveRequest).then((response) => {
			if (response) {
				if (!multiple) {
					clearEdit(record.id);

					if (preventReloadAfterDelete) {
						setItems((advancedItems || items).filter((q) => q.id !== record.id));
					} else if (record.deleted && items.length === 1) {
						onChange({ current: pagination.current - 1 });
					} else {
						reload({ current: pagination.current }, record.id < 0 && !record.deleted && addedFirst)
							.then(() => {
								deleting.current = false;
							});
					}
				} else {
					if (record.deleted) {
						// delete newEdits[record.id];
						setItems((advancedItems || items).filter((q) => q.id !== record.id));
					} else {
						const newEdits = JSON.parse(JSON.stringify({ ...edits }));
						delete newEdits[record.id];
						saveInArray(record, { ...response, id: response.id }, false);
						load({}, false, false, false, false, undefined, newEdits);
					}
					deleting.current = false;
				}
			}
		}).catch(() => {
			if (record.deleted) {
				record.deleted = false;
				deleting.current = false;
			}
		});
	};

	const removeItem = (record, index) => {
		if (record.id > 0) {
			record.deleted = true;

			saveItem(record);
		} else {
			deleteFromArray(index);
		}
	};

	const updateItem = (item: Partial<T>, id: number) => {
		setItems((advancedItems || items).map((el) => (el.id === id ? { ...el, ...item, id } : el)));
	};

	const handleDelete = (e, record, index) => {
		if (CanChange()) {
			if (record.id < 0) {
				handleCancel(record);
			} else if (localRemove) {
				const item = Edit(record);
				if (item) {
					item.deleted = true;
					update((advancedItems || items).map((q: T) => (q.id === record.id ? { ...q, ...edits[record.id] } : q)));
				}
			} else if (removeConfirmation) {
				deleteConfirmation(
					() => {
						removeItem(record, index);
					},
					removeConfirmationText || 'Item will be deleted. Are you Sure?',
					removeConfirmationContainer,
					{
						okButtonProps: {
							'data-confirm': `${record.id}`,
						},
					},
				);
			} else {
				removeItem(record, index);
			}
		}
	};

	const handleAdd = () => {
		if (CanChange()) {
			add();

			// setEdits({ ...edits, [newItem.id]: newItem });
		}
	};

	const handleEdit = (e, record, index) => {
		if (CanChange()) {
			setEdit({ ...record });
		}

		// setEdits({ ...edits, [record.id]: { ...record } });
	};

	const handleCancel = (record) => {
		if (record.id < 0) {
			// setItems(_items.filter(q => q.id !== record.id));
			update((advancedItems || items).filter((q) => q.id !== record.id));

			if (items.length === 1 && pagination.current > 1) {
				onChange({ current: pagination.current - 1 });
			}
		}

		clearEdit(record.id);

		// setEdits({ ...edits, [record.id]: undefined });
	};

	const handleSave = (record, skipValidation?: boolean, saveRequest?: string) => {
		saveItem(record, skipValidation, saveRequest);
	};

	const isDisabled = (record) => {
		return multiple ? false : !!editCount && (edits[record.id]?.id !== record.id);
	};

	const actionsDictionary = {
		[ColumnActions.Modal]: (item: T, props: EditColumnProps) => modalContext?.state && <Button
			isLoading={modalContext.state.id === item?.id && modalContext.state.loading}
			key="modal"
			className="btn btn-sm btn-default"
			type="button"
			title={modalContext.state.buttonTitle}
			onClick={(e) => modalContext.actions.openModal(item)}
		>
			{modalContext.state.buttonChildren}
		</Button>,
		[ColumnActions.Cancel]: (item: T, props: EditColumnProps) => <button
			key="cancel"
			className="btn btn-sm btn-default"
			type="button"
			title="Cancel"
			onClick={(e) => handleCancel(item)}
		>
			<i className="fa fa-times" />
		</button>,
		[ColumnActions.Edit]: (item: T, props: ViewColumnProps) => <button
			key="edit"
			className="btn btn-sm btn-default"
			type="button"
			title="Edit"
			onClick={(e) => handleEdit(e, item, props.index)}
			disabled={isDisabled(item)}
		>
			<i className="fa fa-pencil" />
		</button>,
		[ColumnActions.Save]: (item: T, props: EditColumnProps) => <Button
			key="save"
			className="btn btn-sm btn-primary"
			type="button"
			title="Save"
			onClick={(e) => handleSave(item)}
			isLoading={Loader(item)}
		>
			<i className="fa fa-save" />
		</Button>,
		[ColumnActions.Remove]: (item: T, props: ViewColumnProps) => {
			return <Button
				key="remove"
				className="btn btn-sm btn-danger"
				type="button"
				title="Delete"
				onClick={(e) => handleDelete(e, item, props.index)}
				isLoading={Loader(item)}
				disabled={isDisabled(item)}
			>
				<i className="fa fa-trash" />
			</Button>;
		},
	};

	const showActions = (actions, item: T, props: ViewColumnProps | EditColumnProps, withoutContainer?: boolean) => {
		const actionsElements = actions.map((q) => (typeof q === 'function'
			? q(item, props, { handleDelete, isDisabled, Loader })
			: actionsDictionary[q](item, props)));
		const resElements = adaptiveActions
			? (
				<TableButtons
					record={item}
					buttons={actionsElements.filter((item) => !!item).map((node) => ({
						visible: true,
						node,
					}))}
				/>)
			: actionsElements;

		return (!withoutContainer ? <div
			className={actionsClassName || ''}
		>
			{resElements}
		</div>
			: resElements);
	};

	const actionColumn: EditableTableColumn<T> = {
		...actionColumnProps,
		title: addButton
			? (typeof addButton === 'string' || typeof addButton === 'boolean'
				? (_, record) => {
					return <button
						className="btn btn-sm btn-primary appointment-procedures__btn pull-right"
						type="button"
						title="Add Item"
						onClick={handleAdd}
						disabled={multiple ? false : !!editCount}
					>
						{typeof addButton === 'string' ? addButton : <i className="fa fa-plus" />}
					</button>;
				}
				: addButton)
			: '',
		settingsTitle: actionSettingsTitle,
		dataIndex: 'operation',
		view: (value: string, item: T, props: ViewColumnProps) => showActions(_viewActions, item, props),
		edit: (value: string, item: T, props: EditColumnProps) => showActions(_editActions, item, props),
	};

	if (actionColumnWidth) {
		actionColumn.width = actionColumnWidth;
	}

	const mergedColumns = readonly ? columns : columns.concat(actionColumn);

	const [columnSettings, setSettings] = React.useState<Array<string>>(Array.isArray(defaultColumnSettings)
		? defaultColumnSettings : defaultColumnSettings(mergedColumns));

	React.useEffect(() => {
		if (resetColumnSettings) {
			let newSettings = (Array.isArray(defaultColumnSettings)
				? defaultColumnSettings : defaultColumnSettings(mergedColumns));
			newSettings = newSettings.concat(!readonly && !newSettings.includes('operation') ? 'operation' : []);

			if (`${newSettings}` !== `${columnSettings}`) {
				setSettings(newSettings);
			}
		}
	}, [mergedColumns.length]);

	React.useEffect(() => {
		if (propsColumnSettings) {
			setSettings(propsColumnSettings);
		}
	}, [propsColumnSettings]);

	const convertColumns = (columns: Array<EditableTableColumn<T>>): Array<ColumnType<T>> => {
		return columns.filter(({ enable }) => enable === undefined || enable).map((q) => {
			let dataIndex = q.dataIndex;

			if (!dataIndex && typeof q.title === 'string') {
				dataIndex = q.title as string;
			}
			const { column } = filters;

			return {
				...q,
				children: q.children ? convertColumns(q.children) : undefined,
				dataIndex,
				className: q.className || undefined,
				width: q.width || undefined,
				sortOrder: getSortOrder(column, dataIndex),
				render: (_, record, index) => {
					const item = Edit(record);
					const mainProp : ViewColumnProps<T> = {
						index,
						loading: loaders[record.id],
						update: (values) => updateItem(values, record.id),
						save: (values, saveRequest) => handleSave(values, false, saveRequest),
						updateAndSave: (values, saveRequest) => handleSave(
							{
								...record, ...item, ...values, id: record.id,
							},
							true,
							saveRequest,
						),
						setExpanded: (value) => setExpandedRows((prev) => {
							const key = getKey((item || record));
							return value
								? prev.includes(key) ? prev : prev.concat(key)
								: prev.filter((id) => id !== key);
						}),
						reload: () => reload({ current: pagination.current }),
						getDefaultAction: (actions: Array<ColumnActions>) => <></>,
					};
					mainProp.getDefaultAction = (actions) => showActions(actions, record, mainProp, true);

					let view = q.view;
					let edit = q.edit;
					let needWrap = withMobileView;

					if (withMobileView) {
						if (typeof item !== 'undefined') {
							if (q.mobileEdit) {
								edit = q.mobileEdit;
								needWrap = false;
							}
						} else if (q.mobileView) {
							view = q.mobileView;
							needWrap = false;
						}
					}
					const column = q.title && typeof q.title === 'string' ? q.title || q.settingsTitle : dataIndex;

					let innerNode;

					if (typeof item !== 'undefined') {
						if (edit) {
							const props: EditColumnProps<T> = {
								...mainProp,
								setValue: (key: string, value) => setItemPropValue(item.id, key, value),
								validationWrapper: (
									key: string,
									node: React.ReactNode,
									inputType?: string,
									inputProps?: React.HTMLProps<HTMLInputElement>,
								) => {
									const error = Error(item, key);
									const showError = error && (errors[item?.id as number]?.submitCount || 0) > 0;

									return <div className={showError ? 'has-error' : ''}>
										<div className="is-relative" key={getKey(item)}>
											{node || <input
												className="form-control"
												type={`${inputType || 'text'}`}
												value={item[key]}
												onChange={(event) => {
													setItemPropValue(item?.id as number, key, event.target.value);
												}}
												{...inputProps}
											/>
											}
											{showError ? <div className="validation-message">{error}</div> : ''}
										</div>
									</div>;
								},
								add,
								expand: (value: boolean) => {
									if (typeof expandedRowRender !== 'undefined') {
										const key = getKey(item);
										setExpandedRows((prev) => (value
											? prev.includes(key) ? prev : [...prev, key]
											: prev.includes(key) ? prev.filter((k) => k !== key) : prev));
									}
								},
							};

							props.getDefaultAction = (actions) => showActions(actions, record, props, true);

							innerNode = edit(_, item, props);
						} else {
							innerNode = view(_, item, mainProp);
						}
					} else {
						innerNode = view(_, record, mainProp);
					}

					const node = <div data-column={column}>{innerNode}</div>;

					return needWrap ? renderMobile(q.mobileTitle || q.settingsTitle || q.title, node ?? '-') : node;
				},
			};
		});
	};

	const settingsFilter = (columns: Array<EditableTableColumn<T>>): Array<EditableTableColumn<T>> => {
		return withColumnSettings
			? columns.filter(({ dataIndex }) => !!columnSettings.find((i) => i === dataIndex))
			: columns;
	};

	const onPaginationChange = (current, pageSize) => {
		onChange({ current, pageSize });
	};

	const clearAllHandler = () => {
		deleteConfirmation(
			() => {
				deleteAll();
			},
			'All items will be deleted. Are you Sure?',
			removeConfirmationContainer,
			{
				okButtonProps: {
					'data-confirm': 'all',
				},
			},
		);
	};

	React.useEffect(() => {
		if (afterLoadAdd) {
			setAfterLoadAdd(false);
			add();
		}
	}, [pagination]);

	React.useEffect(() => {
		!skipInitLoad && reload({ current: pagination.current || 1 })
			.catch((err) => (typeof err !== 'string' || !err?.includes('aborted')) && console.log(err));
	}, []);

	React.useEffect(() => {
		!readonly && !columnSettings.includes('operation') && setSettings((prev) => prev.concat('operation'));
	}, [readonly]);

	const resColumns = convertColumns((settingsFilter(mergedColumns)));

	const resultItems = React.useMemo(() => {
		const res = readonly && onlySavedItems ? items.filter(({ id }) => id > 0) : items;
		return localRemove ? res.filter((q) => !q.deleted) : res;
	}, [items, readonly, localRemove]);

	const components = React.useMemo(() => {
		return {
			...antdProps?.components,
			body: {
				...antdProps?.components?.body,
				row: ({ index, ...props }) => {
					const record = props?.children?.[0]?.props?.record;
					const newProps = { index, ...props, 'data-id': record?.id };
					const row = antdProps?.components?.body?.row;

					return row ? row(newProps) : <tr key={props['data-row-key']} {...newProps}>
						{props?.children}
					</tr>;
				},
			},
		};
	}, [antdProps?.components]);

	let tableProps = {
		rowKey: (record) => getKey(record), // `${type}-${record.id}`,
		bordered,
		dataSource: error ? [] : resultItems,
		columns: resColumns.length > 0
			? withSelectedRow && rowSelection.placement === 'right' ? [...resColumns].reverse() : resColumns
			: emptyColumns,
		rowClassName,
		pagination: false,
		onChange: (pagination, tableFilters, sorter, extra) => {
			let column: Array<ColumnFilter> | undefined;

			// for multiple sort. supported since v4 version of antd
			/* if (sorter.column) {
				const newSorter = [{
					caption: sorter.field,
					direction: sorter.order === 'descend' ? SortingDirection.Descending : SortingDirection.Ascending
				}];
				column = filters.column ?
					filters.column
						.filter(({caption}) => caption !== sorter.field)
						.concat(newSorter)
					: newSorter;
			} else {
				column = filters.column ? filters.column.filter(({caption}) => caption !== sorter.field) : undefined;
			} */

			if (sorter.column) {
				column = [{
					caption: sorter.field,
					direction: sorter.order === 'descend' ? SortingDirection.Descending : SortingDirection.Ascending,
				}];
			} else {
				column = undefined;
			}

			onChange && onChange({
				pageSize: pagination.pageSize,
				current: pagination.current,
				column,
			}/* , filters, sorter, extra */);
		},
		...antdProps,
		expandable: {
			...antdProps.expandable,
			rowExpandable: antdProps?.expandable?.rowExpandable
				? (record) => antdProps.expandable?.rowExpandable(Edit(record) || record)
				: undefined,
		},
		className: `${withSelectedRow
			? `${rowSelection.placement === 'right' ? 'table-with-selected-rows' : ''} ${antdProps?.className || ''}`
			: antdProps?.className || ''} ${withMobileView ? 'table-mobile' : ''}`,
		loading: loader ? { indicator: loader, spinning: loading } : loading,
		components,
		id: tableId || type,
		locale: {
			...antdProps?.locale,
			...(error ? { emptyText: customError(error, reload) } : {}),
		},
	};

	if (expandedRowRender) {
		React.useEffect(() => {
			const temp: Array<string> = [];

			for (let i = 0; i < resultItems.length; i++) {
				if (expandShow(resultItems[i], expandedRows)) {
					temp.push(getKey(resultItems[i]));
				}
			}

			setExpandedRows(temp);
		}, [resultItems]);

		const expandedRender: ({ item, setValue, readonly }, index, indent, expanded) => React.ReactNode = expandedRowRender
			|| ((record, index, indent, expanded) => '');
		const _expandIcon = expandIcon || ((props, item) =>
			(showExpandIcon(props, item)
				? <a style={{ color: 'black' }} data-action="Expand" onClick={(e) => { props.onExpand(props.record, e); }}>
					<i className={`fa fa-${props.expanded ? 'minus' : 'plus'}-square-o`} aria-hidden="true" />
				</a>
				: <></>));

		tableProps = {
			...tableProps,
			expandable: {
				rowExpandable: antdProps?.expandable?.rowExpandable
					? (record) => antdProps.expandable?.rowExpandable(Edit(record) || record)
					: undefined,
				expandedRowRender: (record, index, indent, expanded) => expandedRender({
					item: Edit(record) || record,
					readonly: typeof Edit(record) === 'undefined',
					setValue: (key: string, value) => { setItemPropValue(record.id, key, value); },
				}, index, indent, expanded),
				expandedRowKeys: expandedRows,
				expandIcon: (props) => _expandIcon(props, Edit(props.record) || props.record),
				onExpand: (expanded, record: T) => {
					const key = getKey(record);
					setExpandedRows((prevState) => (prevState.includes(key) ? prevState.filter((k) => k !== key) : [...prevState, key]));
				},
			},
		};
	}
	const table = <Table {...tableProps} rowSelection={withSelectedRow ? rowSelection : null} />;

	return <>
		{onResize
			? (
				<ResizeObserverContainer onResize={onResize}>
					{table}
				</ResizeObserverContainer>
			)
			: table}
		<div className="clearfix editable-table-pagination">
			{!hidePagination && <div className="pull-right">
				<Pagination
					{...paginationProps}
					itemRender={(...props) => itemRender(tableId || type)(...props)}
					onChange={onPaginationChange}
					{...pagination}
					pageSizeOptions={pageSizeOptions}
					showSizeChanger={showSizeChanger}
				/>
			</div>}
			{withTotal && pagination.total > 0 ? <div className="pull-left ml20 mt10">
				Total:
				{' '}
				{pagination.total}
			</div> : <></>}
			{withClearAll && items.length && deleteAllRequest ? <a
				onClick={() => clearAllHandler()}
				className="pull-left ml10 mt10"
			>
				Clear All
			</a> : null}
			{withColumnSettings && <div className="mr10 pull-right">
				<CheckboxList
					buttonIcon={<i className="fa fa-cog" />}
					defaultValue={columnSettings}
					values={columnSettings}
					options={mergedColumns.filter(({ enable, hideCheckbox }) => (enable === undefined || enable) && !hideCheckbox)
						.map(({ title, settingsTitle, dataIndex }) => ({
							label: settingsTitle || title,
							value: dataIndex,
						}))
					}
					popoverProps={columnSettingsPopoverProps}
					onChange={(settings) => {
						mergedColumns.forEach(({ hideCheckbox, dataIndex }) => hideCheckbox && settings.push(dataIndex));
						onColumnSettingsChange && onColumnSettingsChange(settings);
						setSettings(settings);
					}}
				/>
			</div>}
		</div>
	</>;
};
