import React from 'react';

import Dropdown from 'antd/lib/dropdown';

import '@common/react/scss/components/buttonsDropdown.scss';

interface ButtonsDropdownProps {
	dropdownValue: JSX.Element;
	overlayClassName?: string;
	innerClassName?: string;
	getPopupContainer?: (node) => HTMLElement;
	placement?: any;
}

const ButtonsDropdown: React.FC<ButtonsDropdownProps> = (props) => {
	const {
		dropdownValue, overlayClassName, innerClassName, children, getPopupContainer, placement = 'bottom',
	} = props;
	return (
		<Dropdown
			placement={placement}
			overlayClassName={overlayClassName}
			getPopupContainer={getPopupContainer}
			menu={{
				items: [{
					key: 1,
					label: <div className={`buttons-dropdown-menu ${innerClassName || ''}`} style={{ margin: '-9px -16px' }}>
						{dropdownValue}
					</div>,
				}],
			}}
		>
			{children}
		</Dropdown>
	);
};

export default ButtonsDropdown;
