import { WithId } from '@common/typescript/objects/WithId';
import { WithDeleted } from '@common/typescript/objects/WithDeleted';
import { Nullable } from '@common/typescript/objects/Nullable';
import { SortingDirection } from '@common/react/components/Core/ItemsProvider/ItemsProvider';

import { BaseAppointment, BaseAppointmentProcedure } from '@commonTuna/react/objects/BaseAppointment';
import { AppointmentStatus as BaseAppointmentStatus } from '@commonTuna/react/objects/AppointmentStatus';

import { IcdCode } from '@app/objects/Codes';
import { Patient } from '@app/objects/Patient';
import { Doctor, DoctorProcedure } from '@app/objects/Doctor';
import { Location } from '@app/objects/Location';
import { User } from '@app/objects/User';
import { Disease } from '@app/objects/Disease';
import { Room } from '@app/objects/Room';
import { Procedure } from '@app/objects/Procedure';
import { AppointmentInsurance } from '@app/objects/AppointmentInsurance';
import { AppointmentFollowUp } from '@app/objects/AppointmentFollowUp';
import { DoctorPayer } from '@app/objects/DoctorPayer';
import { PatientFile } from '@app/objects/PatientFile';
import { DoctorMenu } from '@app/objects/DoctorMenu';

export type AppointmentStatus = BaseAppointmentStatus;

export {
	AppointmentChronoStatus,
	SystemAppointmentStatus,
	AppointmentChronoStatusNames,
	SAStatusNames,
} from '@commonTuna/react/objects/AppointmentStatus';

export { SystemAppointmentType, SATypeNames } from '@commonTuna/react/objects/AppointmentType';
export type { AppointmentType } from '@commonTuna/react/objects/AppointmentType';

export interface ClinicalNote extends WithId {
	appointment: Appointment;
	appointmentId: number;

	locked: boolean;
	updated: number | null;
	path: string;
}

export enum AppointmentMode {
	Appointment,
	NotAppointment
}

export enum DayOffType {
	DayOff,
	Holiday,
	Vacation,
	Lunch,
	NoAppointments,
	Hold,
	NotAvailable,
}

export const DayOffIcon = {
	[DayOffType.DayOff]: 'bed',
	[DayOffType.Holiday]: 'home',
	[DayOffType.Vacation]: 'plane',
	[DayOffType.Lunch]: 'coffee',
	[DayOffType.NoAppointments]: 'calendar-times-o',
	[DayOffType.Hold]: 'lock',
	[DayOffType.NotAvailable]: 'ban',
};

export const DayOffTypeNames = {
	[DayOffType.DayOff]: 'DayOff',
	[DayOffType.Holiday]: 'Holiday',
	[DayOffType.Vacation]: 'Vacation',
	[DayOffType.Lunch]: 'Lunch',
	[DayOffType.NoAppointments]: 'No Appointments',
	[DayOffType.Hold]: 'Hold',
	[DayOffType.NotAvailable]: 'Not Available',
};

export enum DayOffStatus {
	Request,
	Approved,
	Declined
}

export const DayOffStatusNames = {
	[DayOffStatus.Request]: 'Request',
	[DayOffStatus.Approved]: 'Approved',
	[DayOffStatus.Declined]: 'Declined',
};

export enum DayOffObjectType {
	Location,
	Doctor,
	Employee
}

export const DayOffObjectTypeNames = {
	[DayOffObjectType.Location]: 'Location',
	[DayOffObjectType.Doctor]: 'Doctor',
	[DayOffObjectType.Employee]: 'Employee',
};

export enum ReviewStatus {
	None,
	Pending,
	Sent,
	Opened,
	Left,
	Published
}

export const ReviewStatusColor = {
	[ReviewStatus.None]: '#c4c1c1',
	[ReviewStatus.Pending]: '#98d8ec',
	[ReviewStatus.Sent]: '#337ab7',
	[ReviewStatus.Opened]: '#c0e5ae',
	[ReviewStatus.Left]: '#0c960c',
	[ReviewStatus.Published]: '#006400',
};

export const ReviewStatusNames = {
	[ReviewStatus.None]: 'None',
	[ReviewStatus.Pending]: 'Pending',
	[ReviewStatus.Sent]: 'Sent',
	[ReviewStatus.Opened]: 'Opened',
	[ReviewStatus.Left]: 'Left',
	[ReviewStatus.Published]: 'Published',
};

export interface Appointment extends BaseAppointment {
	chronoId: number | null;
	nopaliId: number | null;
	zocDocId: string | null;
	inquiryId: number | null;

	patient: Patient;
	patientId: number | null;

	examRoom: number;

	room: Room | null;
	roomId: number | null;

	location: Location | null;
	locationId: number | null;

	doctorId: number | null;
	doctor: Doctor | null;

	procedure?: Procedure | null;
	procedureId?: number | null;

	signature: string;
	initials: string;
	witnessSignature: string;
	plan: string;
	chiefComplaint: string;

	appointmentMode: AppointmentMode;
	dayOffType: DayOffType;

	diseases: Array<AppointmentDisease>;
	procedures: Array<AppointmentProcedure>;
	clinicalNotes: Array<ClinicalNote>;

	userId: Nullable<number>;
	user: Nullable<User>;

	userUpdaterId: Nullable<number>;
	userUpdater: Nullable<User>;

	employeeId: Nullable<number>;
	employee: Nullable<User>;

	supervisorId: Nullable<number>;
	supervisor: Nullable<User>;

	dayOffStatus: DayOffStatus;

	dayOffObjectType: DayOffObjectType;

	insurance: boolean;

	appointmentInsurance?: AppointmentInsurance;
	appointmentInsuranceId?: number | null;

	wizardPdfPatientFileId: Nullable<number>;

	appointmentFollowUp?: AppointmentFollowUp;
	appointmentFollowUpId?: number | null;

	podiumReviewRequested: boolean;

	totalConsentCount: number;
	totalQuestionsCount: number;
	consentSignedCount: number;
	questionsAnsweredCount: number;
	color?: string;

	portalId: number;

	hasOrdersInProgress: boolean;
	cost: number;
	billed: number;
	payed: number;
	ordersCount: number;

	reviewStatus: ReviewStatus;
	allDay?: boolean;

	commentCount: number;

	// client side only, for PatientComments
	inquiriesCommentCount: number;

	message: string;

	locked: boolean;
	lockedTime: Nullable<number>;
	lockedUserId: Nullable<number>;
	lockedUser: Nullable<User>;
}

export enum AppointmentLogType {
	StatusCreated,
	StatusChanged,
	LockedChanged
}

export enum CaseType {
	BreastAugmentation = 0,
	Other = 1
}

export const AppointmentLogTypeNames = {
	[AppointmentLogType.StatusCreated]: 'Status Created',
	[AppointmentLogType.StatusChanged]: 'Status Changed',
	[AppointmentLogType.LockedChanged]: 'Locked Changed',
};

export interface AppointmentLog extends WithDeleted {
	time: number;

	user: User | null;
	userId: number | null;

	appointment: Appointment;
	appointmentId: number;

	appointmentStatus: AppointmentStatus | null;
	appointmentStatusId: number | null;

	logType: AppointmentLogType;

	locked: boolean;
}

export interface AppointmentDisease extends WithDeleted {
	disease: Disease;
	diseaseId: number;

	appointment: Appointment;
	appointmentId: number;
}

// obsolete
/*
export enum AppointmentProcedureStatus {
	New,
	InProgress,
	Finished
}
*/

export interface AppointmentProcedure extends BaseAppointmentProcedure {
	doctorProcedure: DoctorProcedure | null;
	doctorProcedureId: number;

	appointment: Appointment | null;
	appointmentId: number;

	room: Room | null;
	roomId: number;

	consents: Array<PatientFile>;

	count: number;
	disable: boolean;

	parent: AppointmentProcedure | null;
	parentId: number;

	doctorPayer: DoctorPayer | null;
	doctorPayerId?: number;

	// obsolete
	// status: AppointmentProcedureStatus;

	duration?: string;
	hasInsurance?: boolean;

	googleCalendarEventId: string;

	menuDoctors: Array<AppointmentProcedureMenuDoctor>;
	startProcedureTime?: number;
	doctor?: Doctor;
	doctorId?: number;
}

export interface AppointmentProcedureDisease extends WithDeleted {
	disease: Disease | null;
	diseaseId: number;

	appointmentProcedure: AppointmentProcedure | null;
	appointmentProcedureId: number;
}

export interface AppointmentProcedureIcd extends WithDeleted {
	icdCode: IcdCode | null;
	icdCodeId: number;

	appointmentProcedure: AppointmentProcedure | null;
	appointmentProcedureId: number;

	plan: string;
}

export interface AppointmentProcedureMenuDoctor extends WithDeleted {
	appointmentProcedure: AppointmentProcedure | null;
	appointmentProcedureId: number;

	doctorMenu: DoctorMenu | null;
	doctorMenuId: Nullable<number>;

	doctor: Doctor | null;
	doctorId: number;
}

export enum DayOffPageType {
	Main,
	Doctor,
	Location,
	User
}

export const dayOffPageObjectType = {
	[DayOffPageType.User]: 'employee',
	[DayOffPageType.Location]: 'dayOffLocation',
	[DayOffPageType.Doctor]: 'dayOffDoctor',
};

export enum OrderStatusFilterEnum {
	None = -2,
	All = -1,
	Complete = 0,
	InProgress = 1,
	Canceled = 2,
	Pending = 3
}

export const orderStatusFilterGroup = [
	{ caption: 'Any order status', type: OrderStatusFilterEnum.All },
	{ caption: 'Complete', type: OrderStatusFilterEnum.Complete },
	{ caption: 'In Progress', type: OrderStatusFilterEnum.InProgress },
	{ caption: 'Pending', type: OrderStatusFilterEnum.Pending },
	{ caption: 'Canceled', type: OrderStatusFilterEnum.Canceled },
];

export const refundFilterGroup = [
	{ caption: 'Has Refund', value: true },
	{ caption: 'No Refund', value: false },
	{ caption: 'All', value: null },
];

export const defaultStatusesFilters = {
	column: [{ caption: 'sortOrder', direction: SortingDirection.Ascending }],
	count: 100,
};
